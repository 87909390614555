import React, {useEffect, useRef, useState} from "react";
import AGGrid from "../../Components/AGGrid";
import {CommonUtil, getSelectedRangeRate,calculatePlatformFees} from "../../utils/CommonUtil";
import {IAGrid} from "../../Components/IInterfaces";
import {NetworkManager} from "../../Components/NetworkManager";
import BusyLoader from "../../Components/BusyLoader";
import Flatpickr from "react-flatpickr";
import ToastManager from "../../Components/ToastManager";
import $, {trim} from "jquery";
import {Constants} from "../Languages/Constants";
import useStateWithCallback from "use-state-with-callback";


var renterOrgId:string = "";
var ownerOrgId:string = "";
var rDailyRate:string = "";
var rDistanceRate:string = "";
var rListingType:string = "";
var actuvalStartDate:string = "";
var activationStartDate:string = "";
var activationEndDate:string = "";
var actuvalEndDate:string = "";
let dailyRateNew:string= "";
let newRatePlan:string= "";
let monthlyRateNew:string= "";
let weeklyRateNew:string= "";
let totalDays:number = 0;
var totalDistance:any = "";
var engineHours:string = "";
var rEngineRate:string = "";
var rServiceFee:string = "";
var cFeeOwner:string = "";
var cFeeRenter:string = "";
var pfOwner:string = "";
var pfRenter:string = "";
var pfPaidBy:string = "";
var rMoveRate:string = "";
var rAdditionalRate:string = "";
var rOwnerServiceFee:string = "";
var repositionDays:string = "";

var SAVE_DB:string = "Save DB";
var SAVE_Prextra:string = "Save Prextra";
var PRINT_PDF:string = "Print PDF";
var operation:string = "";
var minServiceFee:number = 80;
var rPricePerKm:number = 0;
var NUMBER_OF_DAYS = "Insurance";
var LIABILITY_INSURANCE = "Liability Insurance";
var PHYSICAL_DAMAGE_INSURANCE = "Physical Damaged Insurance";
const editableFields:any = {excessDays:"",totalDistance:"",engineUnitUsageHours:"",serviceFee:"",physicalInsuranceDays:"",liabilityInsuranceDays:""};

var nRecords:any = [];
var billIdx:number = 0;
var RENTAL:string = "Rental";
var REPOSITION:string = "Reposition";
let RENTAL_DURATION = "Rental Duration";
let DISTANCE = "Distance";
let ENGINE_UNIT_USAGE = "Engine Unit Usage";
let EXCESS_DAYS = "Excess Days";
let REPOSITION_SERVICE_PRICE = "Reposition Service Price";
let SERVICE_FEE = "Service Fee";
let VHUB_SERVICE_FEE = "Owner service fees (paid to vHub)";
var INSURANCE_ADMIN_FEE = "Insurance Administration Fee";
var CONCIERGEFEERENTER = "concierge Fee Renter";
var CONCIERGEFEEOWNER = "concierge Fee Owner";
let liabilityPerDay:number = 0;
let physicalPerDay:number = 0;
let liabilityRateAfterConversion:number = 0;
let physcialRateAfterConversion:number = 0;
let isLiabilityPaidByOwner:boolean = false;
let isPhysicalDamagePaidByOwner:boolean = false;

let INSUR_ADMIN_FEE = 10;

let focusField:any  = "";
let focusFieldName:any  = "";
let lineNo = "";
var dateformat:object = {dateFormat:"m-d-Y", altFormat:'M-d-Y'};
var gridUpdated:boolean = false;
let isInsuranceEnabled:boolean = false;
let isLiabilityEnabled:boolean = false;
let isPhysicalDamageEnabled:boolean = false;

const StartDateRender = (propData:any) => {
    var dateformat:object = {dateFormat:"m-d-Y", altFormat:'M-d-Y'};
    const [stDate,setStDate] = useState<any>("");
    let onChangeRentalStDate = (dateValue:any)=>{
        let strDate:string = CommonUtil.getRentalBillDate(dateValue);
        propData.data.startDate = strDate;
        propData.context.onChangeStartDate(propData.data,strDate);
    }
    useEffect(()=>{
        //setStDate(propData.data.startDate);//?CommonUtil.getServerDate(propData.data.startDate):new Date());
    },[]);
    return(
        <div style={{paddingTop:"3px"}}>
            {propData.data.startDate?<Flatpickr  placeholder="Start Date" value={propData.data.startDate}  options={dateformat} onChange={onChangeRentalStDate}
                                                 className="form-control flat-picker" />:""}
        </div>
    )
}
const endDateRender = (propData:any) => {
    var dateformat:object = {dateFormat:"m-d-Y", altFormat:'M-d-Y'};
    let onChangeRentalStDate = (dateValue:any)=>{
        let strDate:string = CommonUtil.getRentalBillDate(dateValue);
        propData.data.endDate = strDate;
        propData.context.onChangeEndDate(propData.data,strDate);
    }
    return(
        <div style={{paddingTop:"3px"}}>
            {propData.data.endDate?<Flatpickr   placeholder="Start Date" value={propData.data.endDate}  options={dateformat} onChange={onChangeRentalStDate}
                                                 className="form-control flat-picker" />:""}
        </div>
    )
}



/*const insuranceRender = (propData:any) => {
    const onChangeInput = (e:any) => {
        propData.context.onChangeGridInputs(e, propData.data, propData.colDef.field);
    }
    return(
        <div style={{paddingTop:"3px"}}>
            {propData.data.lineItem === NUMBER_OF_DAYS ? (
                <input value={propData.data.perDayInsurance} defaultValue={propData.data.perDayInsurance} onChange={onChangeInput} className="form-control" />
            ):(
                propData.value
            )}
        </div>
    )
}*/

const DistanceRender = (propData:any) => {
    const [disValue,setDisValue] = useState("");
    console.log(focusField);
    const onChangeInput = (e:any) => {gridUpdated =true;
        if(e?.target?.value < 0)
            e.target.value = Math.abs(e?.target?.value);
        propData.context.onChangeGridInputs(e, propData.data, propData.colDef.field);

    }

    return(
        <div style={{paddingTop:"3px"}}>
            {propData.data.lineItem === DISTANCE ? (
            <input value={propData.data.totDis} defaultValue={propData.data.totDis} autoFocus={(focusField == 'totDis'?true:false)} onChange={onChangeInput} className="form-control" />
            ):(
                propData.value
            )}
        </div>
    )
}
const engineRender = (propData:any) => {
    const onChangeInput = (e:any) => {gridUpdated =true;
        propData.context.onChangeGridInputs(e, propData.data, propData.colDef.field);
    }
    const onBlur = (e:any) => {
        e.preventDefault(); e.stopPropagation();
    }
    return(
        <div style={{paddingTop:"3px"}}>
            {propData.data.lineItem === ENGINE_UNIT_USAGE && CommonUtil.EQUIP_RESERVE_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE ? (
            <input value={propData.data.engineHours} defaultValue={propData.data.engineHours} onBlur={onBlur} autoFocus={(focusField == 'engineHours'?true:false)}  onChange={onChangeInput} className="form-control" />
            ):(
                propData.value
            )}
        </div>
    )
}

const serviceRender = (propData:any) => {
    const onChangeInput = (e:any) => {gridUpdated =true;
        console.log(e.target.name);        
        propData.context.onChangeGridInputs(e, propData.data, propData.colDef.field,e.target.name);
    }
    const onBlur = (e:any) => {
        e.preventDefault(); e.stopPropagation();
    }
    return(
        <div style={{paddingTop:"3px"}}>
            {propData.data.lineItem === SERVICE_FEE ? <input name="serviceFee" value={`${propData.data.currency} ${propData.data.amount}`} autoFocus={(focusFieldName == "serviceFee" && focusField == 'amount'?true:false)} 
            defaultValue={`${propData.data.currency} ${propData.data.amount}`} onBlur={onBlur} onChange={onChangeInput} 
            className="form-control" />:
            propData.data.lineItem === CONCIERGEFEEOWNER ? <input name="conciergefeeOwner" value={`${propData.data.currency} ${propData.data.amount}`} autoFocus={(focusFieldName == "conciergefeeOwner" && focusField == 'amount'?true:false)} 
            defaultValue={`${propData.data.currency} ${propData.data.amount}`} onBlur={onBlur} onChange={onChangeInput} 
            className="form-control" />:
            propData.data.lineItem === CONCIERGEFEERENTER? (
            <input name="conciergefeRenter" value={`${propData.data.currency} ${propData.data.amount}`} autoFocus={(focusFieldName == "conciergefeRenter" &&focusField == 'amount'?true:false)} 
            defaultValue={`${propData.data.currency} ${propData.data.amount}`} onBlur={onBlur} onChange={onChangeInput} 
            className="form-control" />
            ):(
               propData.data.currency+ " " +propData.value
            )}
        </div>
    )
}
const excessRender = (propData:any) => {
    const onChangeInput = (e:any) => { gridUpdated =true;
        propData.context.onChangeGridInputs(e, propData.data, propData.colDef.field);
    }
    return(
        <div style={{paddingTop:"3px"}}>
            {/* { propData.data.lineItem === NUMBER_OF_DAYS || propData.data.lineItem === PHYSICAL_DAMAGE_INSURANCE || propData.data.lineItem === LIABILITY_INSURANCE? ( */}
            {propData.data.lineItem === EXCESS_DAYS || propData.data.lineItem === NUMBER_OF_DAYS || propData.data.lineItem === PHYSICAL_DAMAGE_INSURANCE || propData.data.lineItem === LIABILITY_INSURANCE? (
            <input value={propData.data.excessDays} defaultValue={propData.data.excessDays} autoFocus={(propData.data.line == lineNo && focusField == 'excessDays'?true:false)} onChange={onChangeInput}  className="form-control" />
            ):(
                propData.value
            )}
        </div>
    )
}
const rentalExcessRender = (propData:any) => {
    const onChangeInput = (e:any) => { gridUpdated =true;
        propData.context.onChangeGridInputs(e, propData.data, propData.colDef.field);
    }
    return(
        <div style={{paddingTop:"3px"}}>
            {propData.data.lineItem === PHYSICAL_DAMAGE_INSURANCE || propData.data.lineItem === LIABILITY_INSURANCE? (
                <input value={propData.data.totalDays} defaultValue={propData.data.totalDays} autoFocus={(propData.data.line == lineNo && focusField == 'totalDays'?true:false)} disabled={false} onChange={onChangeInput} className="form-control" />
            ):(
                propData.value
            )}
        </div>
    )
}
const insuranceRender = (propData:any) => {
    const onChangeInput = (e:any) => {gridUpdated =true;
        propData.context.onChangeGridInputs(e, propData.data, propData.colDef.field);
    }
    return(
        <div style={{paddingTop:"3px"}}>
            {propData.data.lineItem === PHYSICAL_DAMAGE_INSURANCE || propData.data.lineItem === LIABILITY_INSURANCE ? (
                <input value={propData.data.perDayInsurance} defaultValue={propData.data.perDayInsurance} autoFocus={(focusField == 'perDayInsurance'?true:false)} onChange={onChangeInput} className="form-control" />
            ):(
                propData.value
            )}
        </div>
    )
}

const InvoiceReport = ()=>{

    const BillButtonRender = (propData:any) => {
        const [isChecked,setChecked] = useState(propData?.data?.approval);
    let onChangeApproval = (e:any)=>{
       if(propData?.data?.lineItem === RENTAL_DURATION){
            setRentalDurationApproval(e.target.checked);
           setChecked(e.target.checked);
       }else if(propData?.data?.lineItem === DISTANCE){
        setDistanceApproval(e.target.checked);
           setChecked(e.target.checked);
       }else if(propData?.data?.lineItem === ENGINE_UNIT_USAGE){
        setEngineUsageApproval(e.target.checked);
           setChecked(e.target.checked);
       }else if(propData?.data?.lineItem === EXCESS_DAYS){
        setExcessDaysApproval(e.target.checked);
           setChecked(e.target.checked);
       }else if(propData?.data?.lineItem === REPOSITION_SERVICE_PRICE){
        setMovePriceApproval(e.target.checked);
           setChecked(e.target.checked);
       }else if(propData?.data?.lineItem === SERVICE_FEE){
        setServiceFeeApproval(e.target.checked);
           setChecked(e.target.checked);
       }else if(propData?.data?.lineItem === LIABILITY_INSURANCE){
           setLiabilityInsurApproval(e.target.checked);
           setChecked(e.target.checked);
       }else if(propData?.data?.lineItem === PHYSICAL_DAMAGE_INSURANCE){
           setPhysicalDamageApproval(e.target.checked);
           setChecked(e.target.checked);
       }else if(propData?.data?.lineItem === VHUB_SERVICE_FEE){
           setVhubServiceFeeArroval(e.target.checked);
           setChecked(e.target.checked);
       }else if(propData?.data?.lineItem === INSURANCE_ADMIN_FEE){
           setInsuranceAdminFeeApproval(e.target.checked);
           setChecked(e.target.checked);
       }


        propData.data.approval = e.target.checked;
    }
    useEffect(()=>{

        setChecked(propData?.data?.approval);
    },[]);
    return(
        <>
           <input type={"checkbox"} className="checkBox" checked={(isChecked || propData?.data?.approval)} onChange={(e)=>onChangeApproval(e)}
                  style={{display:(propData && propData.data.desc)?"":"none"}}
           />
        </>
    )
}

    let invoiceColDefs = [
        {field: 'line', headerName: CommonUtil.getMessageText(Constants.LINE,"Line #"), resizable: true, width: 80,sortable: true,  filter: 'agTextColumnFilter', },
        {field: 'lineItem2', headerName: CommonUtil.getMessageText(Constants.LINE_ITEM,"Line Item"), resizable: true, width: 260, sortable: true, filter: 'agTextColumnFilter' ,valueGetter:(params:any)=>params.data.lineItem === INSURANCE_ADMIN_FEE ? "Administration Fee" :  params.data.lineItem},
        {field: 'startDate', headerName: CommonUtil.getMessageText(Constants.START_DATE,"Start Date"), resizable: true, width: 150, sortable: true, filter: 'agTextColumnFilter',cellRenderer:StartDateRender},
        {field: 'endDate', headerName: CommonUtil.getMessageText(Constants.END_DATE,"End Date"), resizable: true, width: 150, sortable: true, filter: 'agTextColumnFilter',cellRenderer:endDateRender},
        {field: 'totalDays', headerName: CommonUtil.getMessageText(Constants.TOTAL_DAYS,"Total Days"), resizable: true, width: 130, sortable: true, filter: 'agTextColumnFilter',cellRenderer:rentalExcessRender},
        {field: 'vHubServiceCharge', headerName: CommonUtil.getMessageText(Constants.VHUB_SERVICE_CHARGE,"vHub Service Charge"), resizable: true, width: 190, sortable: true, filter: 'agTextColumnFilter',},
        {field: 'perDayInsurance', headerName: CommonUtil.getMessageText(Constants.PAY_PER_INSURANCE,"Per Day Insurance"), resizable: true, width: 150, sortable: true, filter: 'agTextColumnFilter'},
        {field: 'totDis', headerName: CommonUtil.getMessageText(Constants.TOTAL_DISTANCE,"Total Distance"), resizable: true, width: 150, sortable: true, filter: 'agTextColumnFilter',cellRenderer:DistanceRender},
        {field: 'engineHours', headerName: CommonUtil.getMessageText(Constants.ENGINE_UNIT_HOURS,"Engine Unit Hours"),resizable: true,  width: 180, sortable: false, filter: 'agTextColumnFilter',cellRenderer:engineRender},
        // {field: 'desc', headerName: 'Descriptions',resizable: true,  width: 350, sortable: false, filter: 'agTextColumnFilter'},
        {field: 'crdr', headerName: CommonUtil.getMessageText(Constants.CR_DR,"CR/DR"),resizable: true,  width: 80, sortable: false, filter: 'agTextColumnFilter'},
        {field: 'amount', headerName: CommonUtil.getMessageText(Constants.AMOUNT,"Amount"),resizable: true,  width: 200, sortable: false, filter: 'agTextColumnFilter',cellRenderer:serviceRender},
        {field: 'approval', headerName: CommonUtil.getMessageText(Constants.VUB_APPROVAL,"vHub Approval"),resizable: true,  width: 250, sortable: false, filter: 'agTextColumnFilter',cellRenderer:BillButtonRender},
    ]

    let invoiceRepColDefs = [
        {field: 'line', headerName: CommonUtil.getMessageText(Constants.LINE,"Line #"), resizable: true, width: 60,sortable: true,  filter: 'agTextColumnFilter', },
        {field: 'lineItem2', headerName: CommonUtil.getMessageText(Constants.LINE_ITEM,"Line Item"), resizable: true, width: 300,sortable: true, filter: 'agTextColumnFilter',valueGetter:(params:any)=>params.data.lineItem === INSURANCE_ADMIN_FEE ? "Administration Fee" :  params.data.lineItem},
        // {field: 'desc', headerName: 'Descriptions',resizable: true,  width: 250, flex:1,sortable: false, filter: 'agTextColumnFilter'},
        {field: 'excessDays', headerName: CommonUtil.getMessageText(Constants.DAYS,"Days"), resizable: true, width: 100, sortable: true, filter: 'agTextColumnFilter', cellRenderer:excessRender},
        {field: 'vHubServiceCharge', headerName: CommonUtil.getMessageText(Constants.VHUB_SERVICE_CHARGE,"vHub Service Charge"), resizable: true, width: 190, sortable: true, filter: 'agTextColumnFilter'},
        {field: 'perDayInsurance', headerName: CommonUtil.getMessageText(Constants.PAY_PER_INSURANCE,"Per Day Insurance"), resizable: true, width: 180, sortable: true, filter: 'agTextColumnFilter'},
        {field: 'totDis', headerName: CommonUtil.getMessageText(Constants.TOTAL_DISTANCE,"Total Distance"), resizable: true, width: 150, sortable: true, filter: 'agTextColumnFilter',cellRenderer:DistanceRender},
        {field: 'engineHours', headerName: CommonUtil.getMessageText(Constants.ENGINE_UNIT_HOURS,"Engine Unit Hours"),resizable: true,  width: 180, sortable: false, filter: 'agTextColumnFilter',cellRenderer:engineRender},
        {field: 'crdr', headerName: CommonUtil.getMessageText(Constants.CR_DR,"CR/DR"),resizable: true,  width: 100,sortable: false, filter: 'agTextColumnFilter'},
        {field: 'amount', headerName: CommonUtil.getMessageText(Constants.AMOUNT,"Amount"),resizable: true,  width: 150, sortable: false, filter: 'agTextColumnFilter',cellRenderer:serviceRender},
        {field: 'approval', headerName: CommonUtil.getMessageText(Constants.VUB_APPROVAL,"vHub Approval"),resizable: true,  width: 150,sortable: false, filter: 'agTextColumnFilter',cellRenderer:BillButtonRender},
    ]

  //  const [rowsData, setRowsData] = useState<any>([]);

    const [rowsData, setRowsData] = useStateWithCallback<any>([], () => {
        setTimeout(()=>{

        },1000);
    })

    const gridRef = useRef<IAGrid>(null);
    const deactivationDateRef = useRef<Flatpickr>(null);

    const [startDate,setStartDate] = useState("");
    const [activationDate,setActivationDate] = useState("");
    const [deActivationDate,setDeActivationDate] = useState("");
    const [reviseDeactivationDate,setRevisedDeactivationDate] = useState("");
    const [companyName, setCompanyName] = useState('');
    const [ownercompanyName, setOwnerCompanyName] = useState('');
    const [days,setDays] = useState(0);
    const [distanceRate,setDistanceRate] = useState("");
    const [vin,setVIN] = useState("");
    const [dailyRate,setDailyRate] = useState("");
    const [monthlyRate,setMonthlyRate] = useState("");
    const [weeklyRate,setWeeklyRate] = useState("");
    const [dailyRatePlan,setDailyRatePlan] = useState("");
    const [engineRate,setEngineRate] = useState("");
    const [movePrice,setMovePrice] = useState("");
    const [addtionalPrice,setAdditionalPrice] = useState<any>("");
    const [pricePerKm,setPricePerKm] = useState<any>("");
    const [serviceFee,setServiceFee] = useState("");
    const [conciergeFeeRenter,setConciergeFeeRenter] = useState<any>("");
    const [conciergeFeeOwner,setConciergeFeeOwner] = useState<any>("");
    const [platformFeePaidBy,setPlatformFeePaidBy] = useState("");
    const [platformFeesOwner,setPlatformFeesOwner] = useState("");
    const [platformFeesRenter,setPlatformFeesRenter] = useState<any>("");
    const [eDistance,setEstimatedDistance] = useState("");
    const [estimatedEngineHours,setEstimatedEngineHours] = useState("");
    const [contactName,setContactName] = useState("");
    const [mail,setMail] = useState("");
    const [ownermail,setOwnerMail] = useState("");
    const [phoneNum,setPhoneNum] = useState("");
    const [ctq, setCTQ] = useState('');
    const [ownerctq, setOwnerCTQ] = useState('');
    const [ownercontactName,setOwnerContactName] = useState("");
    const [ownerphoneNum,setOwnerPhoneNum] = useState("");
    const [listingType,setListingType] = useState("");
    const [reservationCode,setReservationCode] = useState("");
    const [reservationStatus,setReservationStatus] = useState("");
    const [custCost,setCustCost] = useState("");
    const [custPo, setCustPo] = useState("");
    const [renterPo, setRenterPo] = useState("");
    const [ownerCost,setOwnerCost] = useState("");
    const [ownerPo,setOwnerPO] = useState("");
    const [notes,setNotes] = useState("");
    const [custNotes,setCustNotes] = useState("");
    const [noOfDays,setNoOfDays] = useState("");
    const [repExcessDays,setRepExcessDays] = useState(0);
    const [distanceApproval,setDistanceApproval] = useState("N");
    const [engineUsageApproval,setEngineUsageApproval] = useState("N");
    const [excessDaysApproval,setExcessDaysApproval] = useState("N");
    const [movePriceApproval,setMovePriceApproval] = useState("N");
    const [rentalDurationApproval,setRentalDurationApproval] = useState("N");
    const [serviceFeeApproval,setServiceFeeApproval] = useState("N");

    const [insuranceApproval,setInsuranceApproval] = useState("N");

    const [liabilityInsuranceApproval,setLiabilityInsurApproval] = useState("N");
    const [physicalDamageInsuranceApproval,setPhysicalDamageApproval] = useState("N");
    const [vhubServiceFeeArroval,setVhubServiceFeeArroval] = useState("N");
    const [insuranceAdministrationFeeApproval,setInsuranceAdminFeeApproval] = useState("N");

    //const [isLiabilityPaidByOwner, setIsLiabilityPaidByOwner] = useState(false);
   // const [isPhysicalPaidByOwner, setIsPhysicalPaidByOwner] = useState(false);

    const [defCurrency,setDefCurrency] = useState("");
    // const [editableFields, setEditableFields] = useState<any>({excessDays:"",totalDistance:"",engineUnitUsageHours:"",serviceFee:""})
    let firstRender = useRef(true);
    const [isPrextraDisable,setPrextraDisable] = useState(false);
    const [dateOptions,setDateOptions] = useState(dateformat);

    let defaultCurrency = '';
    const usInsuranceRate = '3.75';
    const canadaInsuranceRate = '4.75';
    const REPOSITION = "Reposition";
    const RENTAL = "Rental";
    const USA = "United States";
    const CANADA = "Canada";

    //const [focusField,setFocusField] = useState("");


    useEffect(()=>{
        gridUpdated = false;
        if(localStorage.getItem("Language")){
           // CommonUtil.SELECTED_LANGUAGE = localStorage.getItem("Language");
        }
        focusField = "";
        billIdx = 0;
        liabilityPerDay = 0;
        physicalPerDay = 0;
        isLiabilityPaidByOwner = false;
        isPhysicalDamagePaidByOwner = false;
        isInsuranceEnabled = false;
        isPhysicalDamageEnabled = false;
        isLiabilityEnabled = false;
        getDetails();
    },[]);

    let getDetails = ()=>{
        renterOrgId = "";
        ownerOrgId = "";
        getReservationDetails();
    }

    let getReservationDetails = ()=>{
        if(CommonUtil.SELECT_RESERVATION_ID){
            let reservationAPI:string = "api/reservation/"+CommonUtil.SELECT_RESERVATION_ID;
            console.log(reservationAPI);
            NetworkManager.sendJsonRequest(reservationAPI,"","","GET",true,onGetReservationDetails,onLoginError);
        }
    }
    
    let onGetReservationDetails = (dataObj:any) => {
        console.log("-------Reservation Details-------");
         console.log(JSON.parse(dataObj), "ALLDATA");
         console.log("--------------");
        var reservationData:any = null;
        if (dataObj) {
            getCustomerContractDetails();
            var restData = CommonUtil.parseData(dataObj);

            if (restData) {
                reservationData = restData;
                renterOrgId = restData.renterOrgId;
                ownerOrgId = restData.saasOrgId;
                isInsuranceEnabled = (restData.isInsuranceEnabled == "N")?false:true;

                setStartDate(CommonUtil.getReservationDate(restData?.creationDate)+" (UTC)");
                setActivationDate(CommonUtil.getReservationDate(restData.activationDate));
                setDeActivationDate(CommonUtil.getReservationDate(restData.deactivationDate));
                setReservationCode(restData.reservationCode?restData.reservationCode:"-");
                setReservationStatus(restData.reservationStatus?restData.reservationStatus:"-");
                //setRevisedDeactivationDate(CommonUtil.getReservationBillDate(actuvalEndDate));

                let endDate = new Date(restData.endDate);
                let stDate = new Date(restData.startDate);
                let days =  CommonUtil.getDays(stDate,endDate);

                setDays(days);
                setDistanceRate(restData.distanceRate);
                setDailyRate(restData.dailyRate);
                setMonthlyRate(restData.monthlyRate);
                setWeeklyRate(restData.weeklyRate);
                setDailyRatePlan(restData.dailyRatePlan);
                setEngineRate(restData.engineRate);
                setMovePrice(restData.movePrice);
                setDefCurrency(restData.defaultCurrency);
                // if(restData.listingType === RENTAL){
                //     defaultCurrency = String(restData.departureCountry).toUpperCase() === String(USA).toUpperCase() ? "USD" : String(restData.departureCountry).toUpperCase() === String(CANADA).toUpperCase() ? "CAD" : "";
                // }else {
                //     defaultCurrency = String(restData.arrivalCountry).toUpperCase() === String(USA).toUpperCase() ? "USD" : String(restData.arrivalCountry).toUpperCase() === String(CANADA).toUpperCase() ? "CAD" : "";
                // }
                defaultCurrency = restData.defaultCurrency; 
                setAdditionalPrice(restData.additionalPriceExcessDays);
                setServiceFee(restData.serviceFeePercentage);
                setConciergeFeeOwner(restData.conciergeFeeOwner ? restData.conciergeFeeOwner : 0); 
                setConciergeFeeRenter(restData.conciergeFeeRenter ? restData.conciergeFeeRenter : 0 );
                setPlatformFeePaidBy(restData.platformFeePaidBy);
                setPlatformFeesOwner(restData.platformFeesOwner||0);
                setPlatformFeesRenter(restData.platformFeesRenter || 0);
                setEstimatedDistance(restData.estimatedDistancePerDay);
                setEstimatedEngineHours(restData.estimatedEngineHoursPerDay);
                setListingType(restData.listingType?restData.listingType:"");
                setNoOfDays(restData.noOfDaysAllowed?restData.noOfDaysAllowed:"");
                repositionDays = restData.noOfDaysAllowed?restData.noOfDaysAllowed:"";
                setCustPo(restData?.invoiceResponse?.customerPo)
                setCustCost(restData?.invoiceResponse?.cglRate)
                setOwnerPO(restData?.invoiceResponse?.ownerPo)
                setOwnerCost(restData?.invoiceResponse?.ownerGlRate)
                setNotes(restData?.invoiceResponse?.notes)
                setCustNotes(restData?.invoiceResponse?.customerNotes)
                setDistanceApproval(restData?.invoiceResponse?.distanceApproval);
                setEngineUsageApproval(restData?.invoiceResponse?.engineUsageApproval);
                setExcessDaysApproval(restData?.invoiceResponse?.excessDaysApproval);
                setMovePriceApproval(restData?.invoiceResponse?.movePriceApproval);
                setRentalDurationApproval(restData?.invoiceResponse?.rentalDurationApproval);
                setServiceFeeApproval(restData?.invoiceResponse?.serviceFeeApproval);

                setLiabilityInsurApproval(restData?.invoiceResponse?.liabilityInsuranceApproval);
                setPhysicalDamageApproval(restData?.invoiceResponse?.physicalDamageInsuranceApproval);
                setVhubServiceFeeArroval(restData?.invoiceResponse?.vhubServiceFeeApproval);
                setInsuranceAdminFeeApproval(restData?.invoiceResponse?.insuranceAdminFeeApproval);

                setPricePerKm(restData?.invoiceResponse?.pricePerKm);
                setVIN(restData.vin);
               // setIsLiabilityPaidByOwner(((restData.isInsurancePaidByOwnerLiability === "Y")?true:false));
                //setIsPhysicalPaidByOwner(((restData.isInsurancePaidByOwnerPhysical === "Y")?true:false));

                //console.log(restData.isInsurancePaidByOwnerLiability);
                
                isLiabilityPaidByOwner = false;
                isPhysicalDamagePaidByOwner = false;
                if(restData.isInsurancePaidByOwnerLiability == "Y"){
                    isLiabilityPaidByOwner = true;
                }
                if(restData.isInsurancePaidByOwnerPhysical == "Y"){
                    isPhysicalDamagePaidByOwner = true;
                }

                isLiabilityEnabled = false;
                isPhysicalDamageEnabled = false;
                if(restData.isLiabilityEnabled == "Y"){
                    isLiabilityEnabled = true;
                }
                if(restData.isPhysicalDamageEnabled == "Y"){
                    isPhysicalDamageEnabled = true;
                }


                rPricePerKm = restData?.invoiceResponse?.pricePerKm;
                if(!rPricePerKm){
                    rPricePerKm = 0;
                }

                rListingType = restData.listingType;
                activationStartDate = restData.activationDate;
                activationEndDate = restData.deactivationDate;

                actuvalStartDate = restData.actualStartDate?restData.actualStartDate:restData.activationDate;
                actuvalEndDate = restData.actualEndDate?restData.actualEndDate:restData.deactivationDate;

                // New Rate changes divided to DR/WR/MR
                dailyRateNew = restData.dailyRate?restData.dailyRate:"";
                monthlyRateNew = restData.monthlyRate?restData.monthlyRate:"";
                weeklyRateNew= restData.weeklyRate?restData.weeklyRate:"";

                rDailyRate = restData.dailyRate;
                let deaOdoMeter = restData.deactivationOdometer;
                let actiOdoMeter = restData.activationOdometer;
                if(restData.deactivationOdometer && restData.activationOdometer){
                    totalDistance = (Number(restData.deactivationOdometer)-Number(restData.activationOdometer));
                }else{
                    totalDistance = 0;
                }
                if(restData.deactivationEngineHours && restData.activationEngineHours){
                    engineHours = ((Number(restData.deactivationEngineHours)-Number(restData.activationEngineHours))).toString();
                }else{
                    engineHours = "0";
                }
                //restData.estimatedDistancePerDay;
                //engineHours = restData.estimatedEngineHoursPerDay;
                rDistanceRate = restData.distanceRate;
                rServiceFee = restData.serviceFeePercentage;
                cFeeOwner = restData.conciergeFeeOwner;
                cFeeRenter = restData.conciergeFeeRenter;
                pfOwner = restData.platformFeesOwner;
                pfRenter = restData.platformFeesRenter;
                pfPaidBy = restData.platformFeePaidBy;
                rMoveRate = restData.movePrice;
                newRatePlan = restData.dailyRatePlan;
                rAdditionalRate = restData.additionalPriceExcessDays?restData.additionalPriceExcessDays:restData.dailyRate;

                editableFields.excessDays = restData?.invoiceResponse?.excessDays ? restData?.invoiceResponse?.excessDays : '0';
                editableFields.liabilityInsuranceDays = restData?.invoiceResponse?.liabilityInsuranceNoOfDays? restData?.invoiceResponse?.liabilityInsuranceNoOfDays : '0';
                editableFields.physicalInsuranceDays = restData?.invoiceResponse?.physicalDamageInsuranceNoOfDays ? restData?.invoiceResponse?.physicalDamageInsuranceNoOfDays : '0';
                editableFields.totalDistance = restData?.invoiceResponse?.totalDistance ? restData?.invoiceResponse?.totalDistance : totalDistance;                
                editableFields.engineUnitUsageHours = restData?.invoiceResponse?.engineUnitUsageHours ? restData?.invoiceResponse?.engineUnitUsageHours : (engineHours||'');
                editableFields.serviceFee = restData?.invoiceResponse?.serviceFee ? restData?.invoiceResponse?.serviceFee : '';
                editableFields.conciergeFeeOwner = restData?.invoiceResponse?.conciergeFeeOwner ? restData?.invoiceResponse?.conciergeFeeOwner : '';
                editableFields.conciergeFeeRenter = restData?.invoiceResponse?.conciergeFeeRenter ? restData?.invoiceResponse?.conciergeFeeRenter : '';

                if(restData.engineRate){
                    rEngineRate = restData.engineRate;
                }
                liabilityRateAfterConversion = restData.liabilityRateAfterConversion;
                physcialRateAfterConversion = restData.physcialRateAfterConversion;
                if(restData.insurancePerDayAmountLiability){
                liabilityPerDay = liabilityRateAfterConversion || restData.insurancePerDayAmountLiability;
                }
                if(restData.insurancePerDayAmountPhysical){
                    physicalPerDay = physcialRateAfterConversion ||restData.insurancePerDayAmountPhysical;
                }

                if(deactivationDateRef && deactivationDateRef.current && restData.deactivationDate){
                    let billDate = CommonUtil.getReservationBillDate(restData.actualEndDate?restData.actualEndDate:restData.deactivationDate);
                    console.log(actuvalEndDate,billDate);
                    let deactDate = CommonUtil.getReservationBillDate(restData.activationDate);
                    if(billDate){
                        deactivationDateRef.current.flatpickr.setDate(billDate);
                        deactivationDateRef.current.flatpickr.set("minDate",deactDate);
                    }
                }
            }
        }
       // getReservationPrices();
        //getRenterOrganizationInfo();
       // getRenterUserDetails();
        //getOwnerOrganizationInfo();
        //getOwnerUserDetails();
        //getFinanceInfo();
        //getOwnerFinanceInfo();
       // getOrgServiceFees();

        if(ownerOrgId){
            let listAPI: string = "api/organization/getServiceFees?saasOrgId=" + ownerOrgId;
            NetworkManager.sendJsonRequest(listAPI, "", "", "GET", true, (dataObj: any)=>{
                if (dataObj) {
                    var serviceFeeData = CommonUtil.parseData(dataObj);
                    if (serviceFeeData) {
                       console.log(serviceFeeData.equipmentTypeServiceFees, "DATA");
                       let sFeeArray:any = [];
                       if(serviceFeeData.equipmentTypeServiceFees){
                           if(Array.isArray(serviceFeeData.equipmentTypeServiceFees)){
                               sFeeArray = serviceFeeData.equipmentTypeServiceFees;
                           }else{
                               sFeeArray.push(serviceFeeData.equipmentTypeServiceFees);
                           }

                           for(let i=0;i<sFeeArray.length;i++){
                              if(sFeeArray[i].equipmentType ==  CommonUtil.EQUIP_RESERVE_EQUIPMENT_TYPE){
                                  rOwnerServiceFee = sFeeArray[i].ownerServiceFee;
                                  break;
                              }
                           }
                       }

                    }
                }
                if(!isInsuranceEnabled){
                    let equipAllAPI: string = 'api/insurance/allPolicy';
                    let obj: any = {};
                    NetworkManager.sendJsonRequest(equipAllAPI, '', '', 'GET', true, (dataObj:any)=>{
                        if (dataObj) {
                            let polacies = []
                            var restData = CommonUtil.parseData(dataObj)
                            if (restData) {
                                if (Array.isArray(restData)) {
                                    polacies = restData
                                } else {
                                    polacies.push(restData)
                                }
                            }

                            if(polacies.length>0){
                                console.log(polacies);

                                polacies.forEach((insItem:any,idx:number)=>{
                                    if(insItem.isDefault == "Y"){
                                        if(insItem.insurancePlanType == CommonUtil.PHYSICAL_DAMAGE && isPhysicalDamageEnabled){
                                            physicalPerDay = physcialRateAfterConversion || insItem.perDayInsuranceValue;
                                        }else if(insItem.insurancePlanType == CommonUtil.LIABILITY && isLiabilityEnabled){
                                            liabilityPerDay = liabilityRateAfterConversion || insItem.perDayInsuranceValue;
                                        }
                                    }
                                });

                                if(reservationData){
                                    displayGridRows({distanceApproval:reservationData?.invoiceResponse?.distanceApproval,
                                        engineUsageApproval:reservationData?.invoiceResponse?.engineUsageApproval,
                                        excessDaysApproval:reservationData?.invoiceResponse?.excessDaysApproval,
                                        movePriceApproval:reservationData?.invoiceResponse?.movePriceApproval,
                                        rentalDurationApproval:reservationData?.invoiceResponse?.rentalDurationApproval,
                                        serviceFeeApproval:reservationData?.invoiceResponse?.serviceFeeApproval,
                                        liabilityInsuranceApproval:reservationData?.invoiceResponse?.liabilityInsuranceApproval,
                                        physicalDamageInsuranceApproval:reservationData?.invoiceResponse?.physicalDamageInsuranceApproval,
                                        vhubServiceFeeApproval:reservationData?.invoiceResponse?.vhubServiceFeeApproval,
                                        insuranceAdministrationFeeApproval:reservationData?.invoiceResponse?.insuranceAdministrationFeeApproval
                                    });
                                }
                            }
                        }
                    }, onLoginError);
                }else{
                    if(reservationData){
                        displayGridRows({distanceApproval:reservationData?.invoiceResponse?.distanceApproval,
                            engineUsageApproval:reservationData?.invoiceResponse?.engineUsageApproval,
                            excessDaysApproval:reservationData?.invoiceResponse?.excessDaysApproval,
                            movePriceApproval:reservationData?.invoiceResponse?.movePriceApproval,
                            rentalDurationApproval:reservationData?.invoiceResponse?.rentalDurationApproval,
                            serviceFeeApproval:reservationData?.invoiceResponse?.serviceFeeApproval,
                            liabilityInsuranceApproval:reservationData?.invoiceResponse?.liabilityInsuranceApproval,
                            physicalDamageInsuranceApproval:reservationData?.invoiceResponse?.physicalDamageInsuranceApproval,
                            vhubServiceFeeApproval:reservationData?.invoiceResponse?.vhubServiceFeeApproval,
                            insuranceAdministrationFeeApproval:reservationData?.invoiceResponse?.insuranceAdministrationFeeApproval
                        });
                    }
                }

            }, onLoginError);
        }



        getRenterUserDetails();
        getRenterOrganizationInfo();
        getOwnerUserDetails();
        getOwnerOrganizationInfo();

        getNextReservationProcess();
        setTimeout(()=>{
            loadDisplayGridRows();           
        })
    }

    let getNextReservationProcess = ()=>{
        let reservationAPI:string = "api/dashboard/pendingbillingdetails?listingType="+rListingType;
        NetworkManager.sendJsonRequest(reservationAPI,"","","GET",true,onGetNextProcessDetails,onLoginError);
    }

    let onGetNextProcessDetails = (dataObj:any)=>{
        //console.log(dataObj);
        nRecords = [];
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                if(Array.isArray(restData)){
                    nRecords = restData;
                }else{
                    nRecords.push(restData);
                }
            }
        }
        $("#btnNextProcess").hide();
        if(nRecords.length>1){
            $("#btnNextProcess").show();
        }
    }

    let onClickNextProcess = ()=>{
        //console.log(nRecords[0]);
        let reservationAPI:string = "api/dashboard/pendingbillingdetails?listingType="+rListingType;
        NetworkManager.sendJsonRequest(reservationAPI,"","","GET",true,(dataObj:any)=>{
            nRecords = [];
            if (dataObj) {
                var restData = CommonUtil.parseData(dataObj);
                if (restData) {
                    if(Array.isArray(restData)){
                        nRecords = restData;
                    }else{
                        nRecords.push(restData);
                    }
                }
            }
            if(nRecords.length>0){
                //CommonUtil.SELECT_RESERVATION_ID
                //CommonUtil.SELECT_RESERVATION_ID = ;
                let billObj = nRecords[billIdx];
                if(!billObj){
                    billIdx = 0;
                    billObj = nRecords[billIdx];
                }
                if(nRecords[billIdx].reservationId == CommonUtil.SELECT_RESERVATION_ID){
                    billIdx = billIdx+1;

                }
                if(nRecords.length>billIdx){

                }else{
                    billIdx = 0;
                }
                CommonUtil.SELECT_RESERVATION_ID = nRecords[billIdx].reservationId;
                CommonUtil.SELECT_EQUIP_ID = nRecords[0].equipmentId;
                CommonUtil.EQUIP_RESERVE_ASSET_TYPE = nRecords[0].assetType;
                CommonUtil.EQUIP_RESERVE_EQUIPMENT_TYPE = nRecords[0].equipmentType;
                CommonUtil.EQUIP_RESERVE_IS_GENERIC = nRecords[0].isGeneric;
                CommonUtil.SELECT_EQUIP_AVAIL_ID = nRecords[0].equipmentListingId;
                billIdx = billIdx+1;
                setPrextraDisable(false);
                getDetails();



            }
        },onLoginError);
    }

    let getNewRow = ():any=>{
        let rowObj:any = {};
        rowObj.startDate = ""
        rowObj.endDate = "";
        rowObj.totDis = "-";
        rowObj.currency = "$";
        rowObj.engineHours = "-";
        rowObj.crdr = "+";
        rowObj.desc = "234";
        rowObj.amount = "0";
        return rowObj;
    }

    let getLiabilityInsurance = (days:any,perDayAmt:any):any=>{
        let insurAmount:any = Number(days)*Number(perDayAmt);
        if(insurAmount){
            insurAmount = insurAmount.toFixed(2);
        }
        return insurAmount;
    }

    let getVhubServiceFee = (days:any,serviceFee:any):any=>{
        let ownerSericeAmt:any = Number(days)*Number(serviceFee);
        if(ownerSericeAmt){
            ownerSericeAmt = ownerSericeAmt.toFixed(2);
        }
        return ownerSericeAmt;
    }

    let displayGridRows = (data:any) => {
        let row:any = {};
        let rows:any = [];
        let endDate = new Date((actuvalEndDate?actuvalEndDate:activationEndDate));
        let stDate = new Date(actuvalStartDate?actuvalStartDate:activationStartDate);
        let days =  CommonUtil.getDays(stDate,endDate);
        totalDays = days;
        days = Number(days);
        let noOfRepDays = Number(repositionDays);
        console.log(days,noOfRepDays);
        let eDays = (days>noOfRepDays)?(days-noOfRepDays):0;

        let liabilityDays = 0;
        let phyDamageDays = 0;
        let excessDays = 0;

        if(!gridUpdated){
            excessDays = editableFields.excessDays?editableFields.excessDays:excessDays;
            liabilityDays = Number(editableFields.liabilityInsuranceDays)?editableFields.liabilityInsuranceDays:days;
            phyDamageDays = Number(editableFields.physicalInsuranceDays)?editableFields.physicalInsuranceDays:days;
        }else{
            excessDays = eDays;
            liabilityDays = days;
            phyDamageDays = days;
        }
        editableFields.excessDays = excessDays;
        setRepExcessDays(excessDays);


        if(rListingType == RENTAL){

            row = getNewRow();
           // console.log(actuvalStartDate+","+CommonUtil.getReservationBillDate(actuvalStartDate));

            row.startDate = CommonUtil.getReservationBillDate(actuvalStartDate);//CommonUtil.getDateString(actuvalStartDate);
            row.endDate = CommonUtil.getReservationBillDate(actuvalEndDate);//CommonUtil.getDateString(actuvalEndDate);

            //let durationPrice:any = Number(rDailyRate)*Number(days); // Old Calc
            // const newRates = getSelectedRangeRate(days, +dailyRateNew, +weeklyRateNew, +monthlyRateNew);
            // let durationPrice:any = (Number(newRates.rate)) * (newRates.type === "WEEK" ? Number(newRates.NoofWeeks):newRates.type === "MONTH" ? Number(newRates.NoofMonths): Number(newRates.Noofdays));
            const plan = (dailyRatePlan || newRatePlan);
            let durationPrice:any = (plan ==="Weekly" ? Number(weeklyRateNew)/7 : plan ==="Monthly" ? Number(monthlyRateNew)/30 : Number(rDailyRate))*Number(days); // new Calc
            console.log(plan,durationPrice);
            
            if(durationPrice){
                durationPrice = durationPrice.toFixed(2);
            }

            // console.log(newRates, "NEW")

            row.line = "1";
            row.lineItem = RENTAL_DURATION;
            row.totalDays = days; // Days
            //row.desc = days+"days x  $"+rDailyRate;
           
           
            //row.amount = durationPrice; //Old Price
            row.amount = durationPrice; // New Price
            row.approval = data?.rentalDurationApproval === 'Y' ? true : false;
            rows.push(row);
//Number(days)*
            let distancePrice:any = Number(rDistanceRate)*Number(editableFields?.totalDistance ? editableFields?.totalDistance : totalDistance);
           if(distancePrice){
               distancePrice = distancePrice.toFixed(2);
           }

            row = getNewRow();
            row.line = "2";
            row.lineItem = DISTANCE;
            row.totDis = editableFields?.totalDistance ? editableFields?.totalDistance : totalDistance;;
            //row.desc = days+"days x  $"+totalDistance+" km/day x $"+rDistanceRate;
            row.amount = distancePrice;
            row.approval = data?.distanceApproval === 'Y' ? true : false;
            rows.push(row);

            let totalEnginePrice:any = "";
            if(CommonUtil.EQUIP_RESERVE_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE){//Number(days)
                totalEnginePrice = Number(editableFields?.engineUnitUsageHours ? editableFields?.engineUnitUsageHours : engineHours)*Number(rEngineRate);
                if(totalEnginePrice){
                    totalEnginePrice = totalEnginePrice.toFixed(2);
                }

                row = getNewRow();
                row.line = "3";
                row.lineItem = ENGINE_UNIT_USAGE;
                row.engineHours = editableFields?.engineUnitUsageHours ? editableFields?.engineUnitUsageHours : engineHours;
                row.amount = rEngineRate?(totalEnginePrice):"";
                row.approval = data?.engineUsageApproval === 'Y' ? true : false;
                rows.push(row);
            }else{
                row = getNewRow();
                row.line = "3";
                row.lineItem = ENGINE_UNIT_USAGE;
                //row.approval = data?.engineUsageApproval === 'Y' ? true : false;
                rows.push(row);
            }

            row = getNewRow();
            row.line = "4";
            row.lineItem = LIABILITY_INSURANCE;

            let insurAmount:any = getLiabilityInsurance(liabilityDays,liabilityPerDay);//days*liabilityPerDay;

            row.totalDays = liabilityDays;
            row.perDayInsurance = liabilityPerDay;
            if(isLiabilityPaidByOwner){
                row.crdr = "-";
            }else{
                row.crdr = "+";
            }
            row.amount = insurAmount;
            row.approval = data?.liabilityInsuranceApproval === 'Y' ? true : false;
            rows.push(row);

            row = getNewRow();
            row.line = "5";
            row.lineItem = PHYSICAL_DAMAGE_INSURANCE;

            let phyAmount:any = getLiabilityInsurance(phyDamageDays,physicalPerDay);//days*physicalPerDay;
            //phyAmount = phyAmount.toFixed(2);
            row.totalDays = phyDamageDays;
            row.perDayInsurance = physicalPerDay;

            if(isPhysicalDamagePaidByOwner){
                row.crdr = "-";
            }else{
                row.crdr = "+";
            }
            row.amount = phyAmount;
            row.approval = data?.physicalDamageInsuranceApproval === 'Y' ? true : false;
            rows.push(row);

            var ownerSericeAmt:any = getVhubServiceFee(days,rOwnerServiceFee);//Number(rOwnerServiceFee)*Number(days);

            row = getNewRow();
            row.line = "6";
            row.lineItem = VHUB_SERVICE_FEE;
            row.totalDays = days;

            row.vHubServiceCharge = rOwnerServiceFee;
            row.desc = rServiceFee+"% x";//days+" x days x  $"+restData.estimatedEngineHoursPerDay+" hour/day x $"+restData.estimatedEngineHoursPerDay;

            row.amount = ownerSericeAmt;//`${editableFields.serviceFee && editableFields.serviceFee !== 0 ? editableFields.serviceFee : totServiceFee}`;//(Number(restData.dailyRate)*Number(days)*(restData.estimatedDistancePerDay))+(Number(restData.dailyRate)*Number(days)*(restData.estimatedEngineHoursPerDay))+(Number(restData.dailyRate)*Number(days)*(restData.estimatedEngineHoursPerDay));
            row.approval = data?.vhubServiceFeeApproval === 'Y' ? true : false;
            rows.push(row);

            // let adminFee:any= (Number(insurAmount)+Number(phyAmount))*(INSUR_ADMIN_FEE/100);
            let adminFee:any = Number(String(defaultCurrency || defCurrency).toUpperCase().indexOf("USD") !==-1 ?usInsuranceRate:canadaInsuranceRate);
            // /*-------------------------------remove--2206--------------------
            if(insurAmount <= 0 ){
                adminFee = 0;            
            }
            if(adminFee){
                adminFee = adminFee.toFixed(2);
            }
            row = getNewRow();
            row.line = "7";
            row.lineItem = INSURANCE_ADMIN_FEE;
            row.amount = adminFee;
            row.approval = data?.insuranceAdministrationFeeApproval === 'Y' ? true : false;
            rows.push(row);
            // ---------------------------------2206--------------------*/

            let totPrice:any = Number(durationPrice)+Number(distancePrice);
            if(CommonUtil.EQUIP_RESERVE_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE) {
                if (rEngineRate) {
                    totPrice = totPrice + Number(totalEnginePrice);
                }
            }
            if(insurAmount){
                if(isLiabilityPaidByOwner){
                    //totPrice = totPrice - Number(insurAmount);
                }
                totPrice = totPrice + Number(insurAmount);

            }
            if(phyAmount){
                if(isPhysicalDamagePaidByOwner){
                    //totPrice = totPrice - Number(phyAmount);
                }
                totPrice = totPrice + Number(phyAmount);
            }
            let totServiceFee:any = totPrice*(Number(rServiceFee)/100);
            totServiceFee = Number(totServiceFee)+Number(ownerSericeAmt)//+Number(adminFee);
            if(totServiceFee>minServiceFee){
                totServiceFee = totServiceFee.toFixed(2);
            }else{
                totServiceFee = minServiceFee;
            }
            //totServiceFee = totServiceFee.toFixed(2);
            row = getNewRow();
            row.line = "8";// Number Changed here. line number 7 is commented
            row.lineItem = SERVICE_FEE;
            //row.desc = rServiceFee+"% x"+totPrice;//days+" x days x  $"+restData.estimatedEngineHoursPerDay+" hour/day x $"+restData.estimatedEngineHoursPerDay;
            row.amount = totServiceFee;//`${editableFields.serviceFee && editableFields.serviceFee !== 0 ? editableFields.serviceFee : totServiceFee}`;//(Number(restData.dailyRate)*Number(days)*(restData.estimatedDistancePerDay))+(Number(restData.dailyRate)*Number(days)*(restData.estimatedEngineHoursPerDay))+(Number(restData.dailyRate)*Number(days)*(restData.estimatedEngineHoursPerDay));
            row.approval = data?.serviceFeeApproval === 'Y' ? true : false;
            rows.push(row);


            //----------------2737------------------
            row = getNewRow();
            row.line = "9";
            row.lineItem = CONCIERGEFEERENTER;
            // row.desc = rServiceFee+"% x"+totPrice;//days+" x days x  $"+restData.estimatedEngineHoursPerDay+" hour/day x $"+restData.estimatedEngineHoursPerDay;
            row.amount = cFeeRenter||conciergeFeeRenter;//`${editableFields.serviceFee && editableFields.serviceFee !== 0 ? editableFields.serviceFee : totServiceFee}`;//(Number(restData.dailyRate)*Number(days)*(restData.estimatedDistancePerDay))+(Number(restData.dailyRate)*Number(days)*(restData.estimatedEngineHoursPerDay))+(Number(restData.dailyRate)*Number(days)*(restData.estimatedEngineHoursPerDay));
            row.approval = data?.serviceFeeApproval === 'Y' ? true : false;
            rows.push(row);

            row = getNewRow();
            row.line = "10";
            row.lineItem = CONCIERGEFEEOWNER;
            // row.desc = rServiceFee+"% x"+totPrice;//days+" x days x  $"+restData.estimatedEngineHoursPerDay+" hour/day x $"+restData.estimatedEngineHoursPerDay;
            row.amount = cFeeOwner || conciergeFeeOwner;//`${editableFields.serviceFee && editableFields.serviceFee !== 0 ? editableFields.serviceFee : totServiceFee}`;//(Number(restData.dailyRate)*Number(days)*(restData.estimatedDistancePerDay))+(Number(restData.dailyRate)*Number(days)*(restData.estimatedEngineHoursPerDay))+(Number(restData.dailyRate)*Number(days)*(restData.estimatedEngineHoursPerDay));
            row.approval = data?.serviceFeeApproval === 'Y' ? true : false;
            rows.push(row);
//----------------2737------------------

             totPrice = Number(durationPrice)+Number(distancePrice);
            if(rEngineRate){
                totPrice = totPrice+Number(totalEnginePrice);
            }
            if(insurAmount){
                if(isLiabilityPaidByOwner){
                    //totPrice = totPrice-Number(insurAmount);
                }
                totPrice = totPrice+Number(insurAmount);
            }
            if(phyAmount){
                if(isPhysicalDamagePaidByOwner){
                    //totPrice = totPrice-Number(phyAmount);
                }
                totPrice = totPrice+Number(phyAmount);
            }
            totPrice = totPrice+Number(ownerSericeAmt)+Number(totServiceFee)+Number(adminFee);//editableFields?.serviceFee ? editableFields.serviceFee : totServiceFee);
            if(!!conciergeFeeOwner){
                totPrice = totPrice+Number(conciergeFeeOwner);
            }
            if(!!conciergeFeeRenter){
                totPrice = totPrice+Number(conciergeFeeRenter);
            }
            totPrice = totPrice.toFixed(2);

            row = getNewRow();
            row.crdr = "Total";
            row.amount = totPrice;
            rows.push(row);
        }else{

            const excessTotal = Number(excessDays) * Number(rAdditionalRate);//editableFields.excessDays
            const pfObj = calculatePlatformFees({
                days,noOfRepDays,excessDays,rMoveRate,pfPaidBy,rAdditionalRate
            })
            pfOwner = pfObj.platformFeesOwner?.toFixed(2);
            pfRenter = pfObj.platformFeesRenter?.toFixed(2);
            // console.log(platformFeesOwnerTemp,platformFeesRenterTemp);
            
            row = getNewRow();
            row.line = "1";
            row.lineItem = REPOSITION_SERVICE_PRICE;
            row.desc ="$"+rMoveRate;

            row.amount = rMoveRate;
            row.approval = data?.movePriceApproval === 'Y' ? true : false;
            rows.push(row);

            
            row = getNewRow();
            row.line = "2";
            row.lineItem = EXCESS_DAYS;
            row.excessDays = excessDays;//editableFields.excessDays;
            row.amount = excessTotal;
            row.approval = data?.excessDaysApproval === 'Y' ? true : false;
            rows.push(row);

            //console.log("Distance:"+editableFields?.totalDistance);
            let distancePrice:any = Number(rPricePerKm)*Number( editableFields?.totalDistance ? editableFields?.totalDistance : totalDistance);
            if(distancePrice){
                distancePrice = distancePrice.toFixed(2);
            }

            row = getNewRow();
            row.line = "3";
            row.lineItem = DISTANCE;
            row.totDis = editableFields?.totalDistance ? editableFields?.totalDistance : totalDistance;
            row.desc = days+"days x  $"+totalDistance+" km/day x $"+rDistanceRate;
            row.amount = distancePrice;
            row.approval = data?.distanceApproval === 'Y' ? true : false;
            rows.push(row);

            let totalEnginePrice:any = "";
            if(CommonUtil.EQUIP_RESERVE_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE){
                totalEnginePrice = Number(engineHours)*Number(days)*Number(rEngineRate);
                totalEnginePrice = totalEnginePrice.toFixed(2);
                row = getNewRow();
                row.line = "4";
                row.lineItem = ENGINE_UNIT_USAGE;

                // row.engineHours = rEngineRate?engineHours:"-";
                row.engineHours = editableFields?.engineUnitUsageHours ? editableFields?.engineUnitUsageHours : engineHours;
                row.desc = rEngineRate?(days+"days x  $"+engineHours+" hour/day x $"+rEngineRate):"-";

                row.amount = rEngineRate?(totalEnginePrice):"0";
                row.approval = data?.engineUsageApproval === 'Y' ? true : false;
                rows.push(row);
            }else{
                row = getNewRow();
                row.line = "4";
                row.lineItem = ENGINE_UNIT_USAGE;
                row.approval = data?.engineUsageApproval === 'Y' ? true : false;
                rows.push(row);
            }

            row = getNewRow();
            row.line = "5";
            row.lineItem = LIABILITY_INSURANCE;
            row.startDate = "";
            row.endDate = "";

            let iTotDays = Number(liabilityDays);//Number(editableFields.excessDays)+Number(days);
            let insurAmount:any = getLiabilityInsurance(iTotDays,liabilityPerDay);//days*perDay;
            row.excessDays = iTotDays;
            row.perDayInsurance = liabilityPerDay;

            row.desc = "5623";
            if(isLiabilityPaidByOwner){
                row.crdr = "-";
            }else{
                row.crdr = "+";
            }


            row.amount = insurAmount;
            row.approval = data?.liabilityInsuranceApproval === 'Y' ? true : false;
            rows.push(row);

            row = getNewRow();
            row.line = "6";
            row.lineItem = PHYSICAL_DAMAGE_INSURANCE;

             iTotDays = Number(phyDamageDays);
            let physicalAmount:any = getLiabilityInsurance(iTotDays,physicalPerDay);;//*Number(editableFields.excessDays);;
            row.excessDays = iTotDays;
            row.perDayInsurance = physicalPerDay;
            row.engineHours = "";
            row.desc = "5623";
            if(isPhysicalDamagePaidByOwner){
                row.crdr = "-";
            }else{
                row.crdr = "+";
            }
            row.amount = physicalAmount;
            row.approval = data?.physicalDamageInsuranceApproval === 'Y' ? true : false;
            rows.push(row);

            var ownerSericeAmt:any = 0;
            //var ownerSericeAmt:any = getVhubServiceFee(iTotDays,rOwnerServiceFee);//Number(rOwnerServiceFee)*Number(editableFields.excessDays);


            /*row = getNewRow();
            row.line = "7";
            row.lineItem = VHUB_SERVICE_FEE;
            row.excessDays = iTotDays;//editableFields.excessDays;
            row.vHubServiceCharge = rOwnerServiceFee;
            row.desc = rServiceFee+"% x";//days+" x days x  $"+restData.estimatedEngineHoursPerDay+" hour/day x $"+restData.estimatedEngineHoursPerDay;
            row.amount = ownerSericeAmt;//`${editableFields.serviceFee && editableFields.serviceFee !== 0 ? editableFields.serviceFee : totServiceFee}`;//(Number(restData.dailyRate)*Number(days)*(restData.estimatedDistancePerDay))+(Number(restData.dailyRate)*Number(days)*(restData.estimatedEngineHoursPerDay))+(Number(restData.dailyRate)*Number(days)*(restData.estimatedEngineHoursPerDay));
            row.approval = data?.vhubServiceFeeApproval === 'Y' ? true : false;
            rows.push(row);*/

            // let adminFee:any= (Number(insurAmount)+Number(physicalAmount))*(INSUR_ADMIN_FEE/100);
            let adminFee:any = Number(String(defaultCurrency||defCurrency).toUpperCase().indexOf("USD") !==-1 ?usInsuranceRate:canadaInsuranceRate);
            //-------------------------------removed--2206--------------------
            if(insurAmount <= 0 ){
                adminFee = 0;            
            }
            if(adminFee){
                adminFee = adminFee.toFixed(2);
            }
            row = getNewRow();
            row.line = "7";
            row.lineItem = INSURANCE_ADMIN_FEE;
            row.amount = adminFee;
            row.approval = data?.insuranceAdministrationFeeApproval === 'Y' ? true : false;
            rows.push(row);
            // ---------------------------------2206--------------------
            

            let totPrice:any = Number(rMoveRate)+Number(distancePrice)+Number(excessTotal)+Number(insurAmount)+Number(physicalAmount);
            if(CommonUtil.EQUIP_RESERVE_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE) {
                if (rEngineRate) {
                    totPrice = totPrice + Number(totalEnginePrice);
                }
            }
            let totServiceFee:any = totPrice*(Number(rServiceFee)/100);
            totServiceFee = Number(totServiceFee)+Number(ownerSericeAmt)//+Number(adminFee);
            if(totServiceFee>minServiceFee){
                totServiceFee = totServiceFee.toFixed(2);
            }else{
                totServiceFee = minServiceFee;
            }
console.log(cFeeOwner,cFeeRenter,rServiceFee);

            row = getNewRow();
            row.line = "8";
            row.lineItem = SERVICE_FEE;
            row.desc = rServiceFee+"% x"+totPrice;//days+" x days x  $"+restData.estimatedEngineHoursPerDay+" hour/day x $"+restData.estimatedEngineHoursPerDay;
            row.amount = totServiceFee;//`${editableFields.serviceFee && editableFields.serviceFee !== 0 ? editableFields.serviceFee : totServiceFee}`;//(Number(restData.dailyRate)*Number(days)*(restData.estimatedDistancePerDay))+(Number(restData.dailyRate)*Number(days)*(restData.estimatedEngineHoursPerDay))+(Number(restData.dailyRate)*Number(days)*(restData.estimatedEngineHoursPerDay));
            row.approval = data?.serviceFeeApproval === 'Y' ? true : false;
            rows.push(row);
//----------------2737------------------
            row = getNewRow();
            row.line = "9";
            row.lineItem = CONCIERGEFEERENTER;
            // row.desc = rServiceFee+"% x"+totPrice;//days+" x days x  $"+restData.estimatedEngineHoursPerDay+" hour/day x $"+restData.estimatedEngineHoursPerDay;
            row.amount = cFeeRenter || conciergeFeeOwner;//`${editableFields.serviceFee && editableFields.serviceFee !== 0 ? editableFields.serviceFee : totServiceFee}`;//(Number(restData.dailyRate)*Number(days)*(restData.estimatedDistancePerDay))+(Number(restData.dailyRate)*Number(days)*(restData.estimatedEngineHoursPerDay))+(Number(restData.dailyRate)*Number(days)*(restData.estimatedEngineHoursPerDay));
            row.approval = data?.serviceFeeApproval === 'Y' ? true : false;
            rows.push(row);

            row = getNewRow();
            row.line = "10";
            row.lineItem = CONCIERGEFEEOWNER;
            // row.desc = rServiceFee+"% x"+totPrice;//days+" x days x  $"+restData.estimatedEngineHoursPerDay+" hour/day x $"+restData.estimatedEngineHoursPerDay;
            row.amount =  cFeeOwner || conciergeFeeRenter;//`${editableFields.serviceFee && editableFields.serviceFee !== 0 ? editableFields.serviceFee : totServiceFee}`;//(Number(restData.dailyRate)*Number(days)*(restData.estimatedDistancePerDay))+(Number(restData.dailyRate)*Number(days)*(restData.estimatedEngineHoursPerDay))+(Number(restData.dailyRate)*Number(days)*(restData.estimatedEngineHoursPerDay));
            row.approval = data?.serviceFeeApproval === 'Y' ? true : false;
            rows.push(row);

            row = getNewRow();
            row.line = "11";
            row.lineItem = "PlatformFee Paid By Owner";
            // row.desc = rServiceFee+"% x"+totPrice;//days+" x days x  $"+restData.estimatedEngineHoursPerDay+" hour/day x $"+restData.estimatedEngineHoursPerDay;
            row.amount = pfOwner|| platformFeesOwner;//`${editableFields.serviceFee && editableFields.serviceFee !== 0 ? editableFields.serviceFee : totServiceFee}`;//(Number(restData.dailyRate)*Number(days)*(restData.estimatedDistancePerDay))+(Number(restData.dailyRate)*Number(days)*(restData.estimatedEngineHoursPerDay))+(Number(restData.dailyRate)*Number(days)*(restData.estimatedEngineHoursPerDay));
            row.approval = data?.serviceFeeApproval === 'Y' ? true : false;
            rows.push(row);

            row = getNewRow();
            row.line = "12";
            row.lineItem = "PlatformFee Paid By Renter";
            // row.desc = rServiceFee+"% x"+totPrice;//days+" x days x  $"+restData.estimatedEngineHoursPerDay+" hour/day x $"+restData.estimatedEngineHoursPerDay;
            row.amount = pfRenter || platformFeesRenter;//`${editableFields.serviceFee && editableFields.serviceFee !== 0 ? editableFields.serviceFee : totServiceFee}`;//(Number(restData.dailyRate)*Number(days)*(restData.estimatedDistancePerDay))+(Number(restData.dailyRate)*tformFeesRenter : '';//`${editableFields.serviceFee && editableFields.serviceFee !== 0 ? editableFields.serviceFee : totServiceFee}`;//(Number(restData.dailyRate)*Number(days)*(restData.estimatedDistancePerDay))+(Number(restData.dailyRate)*Number(days)*(restData.estimatedEngineHoursPerDay))+(Number(restData.dailyRate)*Number(days)*(restData.estimatedEngineHoursPerDay));
            row.approval = data?.serviceFeeApproval === 'Y' ? true : false;
            rows.push(row);
//----------------2737------------------
//----------------2705------------------
            // row = getNewRow();
            // row.line = "12";
            // row.lineItem = "Miscellaneous";
            // // row.desc = rServiceFee+"% x"+totPrice;//days+" x days x  $"+restData.estimatedEngineHoursPerDay+" hour/day x $"+restData.estimatedEngineHoursPerDay;
            // row.amount = pfRenter || platformFeesRenter;//`${editableFields.serviceFee && editableFields.serviceFee !== 0 ? editableFields.serviceFee : totServiceFee}`;//(Number(restData.dailyRate)*Number(days)*(restData.estimatedDistancePerDay))+(Number(restData.dailyRate)*tformFeesRenter : '';//`${editableFields.serviceFee && editableFields.serviceFee !== 0 ? editableFields.serviceFee : totServiceFee}`;//(Number(restData.dailyRate)*Number(days)*(restData.estimatedDistancePerDay))+(Number(restData.dailyRate)*Number(days)*(restData.estimatedEngineHoursPerDay))+(Number(restData.dailyRate)*Number(days)*(restData.estimatedEngineHoursPerDay));
            // row.approval = data?.serviceFeeApproval === 'Y' ? true : false;
            // rows.push(row);
//----------------2705------------------
             totPrice = Number(adminFee)+Number(ownerSericeAmt)+Number(rMoveRate)+Number(distancePrice)+Number(excessTotal)+Number(totServiceFee);
            if(!!conciergeFeeOwner){
                totPrice = totPrice+Number(conciergeFeeOwner);
            }
            if(!!conciergeFeeRenter){
                totPrice = totPrice+Number(conciergeFeeRenter);
            }

            if(!!pfRenter){
                totPrice = totPrice+Number(pfRenter);
            }
            if(!!pfOwner){
                totPrice = totPrice+Number(pfOwner);
            }

            if(rEngineRate){
                totPrice = totPrice+Number(totalEnginePrice);
            }
            if(insurAmount){
                totPrice = totPrice+Number(insurAmount);
            }
            if(physicalAmount){
                totPrice = totPrice+Number(physicalAmount);
            }
            //totPrice = totPrice+Number(totServiceFee);//editableFields?.serviceFee ? editableFields.serviceFee : totServiceFee) + Number(excessTotal);
            if(totPrice){
                totPrice = totPrice.toFixed(2);
            }


            row = getNewRow();
            row.crdr = "Total";
            row.amount = totPrice;
            rows.push(row);
        }
        // gridUpdated = true;
        setRowsData(rows);
    }
    let getRenterUserDetails = () => {
        let getUserAPI: string = 'api/organization/'+renterOrgId+'/defaultuser';// + CommonUtil.getUserId() + '?saasOrgId=' + CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(getUserAPI, '', '', 'GET', true, onGetUserDetails, onLoginError)
    }
    let onGetUserDetails = (dataObj: any) => {
       // console.log("-------Renter User Details-------");
        //console.log(dataObj)
        //console.log("--------------");
        BusyLoader.hideLoader();
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setContactName(restData.firstName+" "+restData.lastName);
                setMail(restData.loginId?restData.loginId:"--");
                setPhoneNum(restData.phone?restData.phone:"--");
                //setLastName(restData.lastName);
                // setEmailId(restData.loginId);
            }
        }
    }
    let getRenterOrganizationInfo = () => {
        var qParam = renterOrgId;//CommonUtil.getSaasOrgId();
        var orgUrl = 'api/organization/' + qParam;
        NetworkManager.sendJsonRequest(orgUrl, '', '', 'GET', true, onOrgDetailsSuccess, onLoginError)
    }
    let onOrgDetailsSuccess = (dataObj: any) => {
        //console.log("-------Renter Org Details-------");
        //console.log(dataObj)
       // console.log("--------------");
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setCompanyName(restData.orgName);
                setCTQ(restData.transportCommissionNumber?restData.transportCommissionNumber:"--");
                //setPhoneNum(restData.phoneNumber);


            }
        }
    }

    let getCustomerContractDetails = () => {
        let customerAPI: string = `api/reservation/getCustomerContractDetails?reservationId=${CommonUtil.SELECT_RESERVATION_ID}`
        NetworkManager.sendJsonRequest(customerAPI, "", "", "GET", true, onGetCustomerContractDetails, onLoginError);
    }
    let onGetCustomerContractDetails = (data: any) => {
        var restData = CommonUtil.parseData(data);

        console.log(restData)
        setCustPo(restData.custPo);
        setRenterPo(restData.renterPo)
    }

    let getOwnerOrganizationInfo = () => {
        var qParam = ownerOrgId;//CommonUtil.getSaasOrgId();
        var orgUrl = 'api/organization/' + qParam;
        NetworkManager.sendJsonRequest(orgUrl, '', '', 'GET', true, onOwnerOrgDetailsSuccess, onLoginError)
    }
    let onOwnerOrgDetailsSuccess = (dataObj: any) => {
        //console.log("-------Owner Org Details-------");
       // console.log(dataObj)
       // console.log("--------------");
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setOwnerCompanyName(restData.orgName);
                setOwnerCTQ(restData.transportCommissionNumber?restData.transportCommissionNumber:"--");
                //setOwnerPhoneNum(restData.phoneNumber);
            }
        }
    }
    let getOwnerUserDetails = () => {
        let getUserAPI: string = 'api/organization/'+ownerOrgId+'/defaultuser';// + CommonUtil.getUserId() + '?saasOrgId=' + CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(getUserAPI, '', '', 'GET', true, onGetOwnerUserDetails, onLoginError)
    }
    let onGetOwnerUserDetails = (dataObj: any) => {
        //console.log("-------Owner User Details-------");
        //console.log(dataObj)
        //console.log("--------------");
        BusyLoader.hideLoader();
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setOwnerContactName(restData.firstName+" "+restData.lastName);
                setOwnerMail(restData.loginId);
                setOwnerPhoneNum(restData.phone);
                //setLastName(restData.lastName);
                // setEmailId(restData.loginId);
            }
        }
    }

    let onChangeDisRate = (e:any)=>{
       setDistanceRate(e.target.value);
    }
    let onChangeDistanceRate = (event: any) => {
        let e = CommonUtil.validateInput(event);
        if(e && e.target){
            focusField = "";
            rDistanceRate = CommonUtil.ifDecimal(e.target.value);
            setDistanceRate(CommonUtil.ifDecimal(e.target.value));
            loadDisplayGridRows();
        }

        //CommonUtil.isMandatory(legalRef)
    }

    let onChangeDailyRate = (e:any)=>{
       // let e = CommonUtil.validateInput(event)
        //setDailyRate(e.target.value);//CommonUtil.ifDecimal(e.target.value));
        //loadDisplayGridRows();

        if(e && e.target){
            focusField = "";
            rDailyRate = CommonUtil.ifDecimal(e.target.value);
            setDailyRate(rDailyRate);//CommonUtil.ifDecimal(e.target.value));
            dailyRateNew = rDailyRate;
            loadDisplayGridRows();
        }

    }

    let onChangeWeeklyRate = (e:any)=>{
         if(e && e.target){
             focusField = "";
             weeklyRateNew = e.target.value;
             loadDisplayGridRows();
         }
 
     }

     let onChangeMonthlyRate = (e:any)=>{
        if(e && e.target){
            focusField = "";
            monthlyRateNew = e.target.value;
            loadDisplayGridRows();
        }

    }

    let onChangeEngineRate = (event:any)=>{
        const e = CommonUtil.validateInput(event);
        if(e && e.target){
            focusField = "";
            rEngineRate = CommonUtil.ifDecimal(e.target.value);
            setEngineRate(CommonUtil.ifDecimal(e.target.value));
            loadDisplayGridRows();
        }

    }

    let onChangeMoveRate = (event:any)=>{
        const e = CommonUtil.validateInput(event);
        if(e && e.target){
            focusField = "";
            setMovePrice(CommonUtil.ifDecimal(e.target.value));
            rMoveRate = CommonUtil.ifDecimal(e.target.value);
            loadDisplayGridRows();
        }

    }

    let onChangeAdditionalRate = (event:any)=>{
        const e = CommonUtil.validateInput(event);
        if(e && e.target){
            focusField = "";
            setAdditionalPrice(CommonUtil.ifDecimal(e.target.value));
            rAdditionalRate = CommonUtil.ifDecimal(e.target.value);
            loadDisplayGridRows();
        }

    }

    let onChangePricePerKM = (event:any)=>{
        const e = CommonUtil.validateInput(event);
        if(e && e.target){
            focusField = "";
            setPricePerKm(CommonUtil.ifDecimal(e.target.value));
            rPricePerKm = CommonUtil.ifDecimal(e.target.value);
            loadDisplayGridRows();
        }

    }

    let onChangeStartDate = (data: any,stDate:any)=>{
        actuvalStartDate = stDate;
        focusField = "";
        loadDisplayGridRows();
    }

    let onChangeEndDate = (data: any,edDate:any)=>{
        actuvalEndDate = edDate;
        focusField = "";
        loadDisplayGridRows();
    }

    const calculateTotal = (position:any) => {
        let rows = [...rowsData];
        rows.splice(position, 1);
        rows.pop();
        var total:any = 0;
            rows.forEach(item => {
                console.log(item.amount, 'Hello',item.lineItem)
                total += Number(item.amount.toString().trim());
            });
            if(total){
                total = total.toFixed(2);
            }
        return total;
    }

    let calculateServiceFee = (rows:any)=>{
        let tAmount = 0;
        let i:number = 0;
        let adminServiceFee:number = 0;
        let insurAdminFee:number = 0;
        if(listingType == RENTAL){
            for(i=0;i<rows.length-3;i++){
               // console.log((rows[i]));
                if(rows[i].lineItem == VHUB_SERVICE_FEE || rows[i].lineItem == INSURANCE_ADMIN_FEE||rows[i].lineItem == "Service Fee"){
                    if(rows[i].lineItem == VHUB_SERVICE_FEE){
                        adminServiceFee = rows[i].amount;
                    }
                    if(rows[i].lineItem == INSURANCE_ADMIN_FEE){
                        insurAdminFee = rows[i].amount;
                    }
                    
                    continue;
                }
                tAmount = tAmount+Number(rows[i].amount);
                //+Number(rows[1].amount)+Number(rows[2].amount)+Number(rows[3].amount)+Number(rows[4].amount)+Number(rows[5].amount);
            }
            let totServiceFee:any = tAmount*(Number(rServiceFee)/100);
            totServiceFee = Number(adminServiceFee)+Number(totServiceFee)+Number(insurAdminFee);
            if(totServiceFee>minServiceFee){
                totServiceFee = totServiceFee.toFixed(2);
            }else{
                totServiceFee = minServiceFee;
            }

            rows[rows.length-4].amount = totServiceFee;
        }else{
            for(i=0;i<rows.length-4;i++){
               // console.log(Number(rows[i].amount),tAmount);
               if(rows[i].lineItem == VHUB_SERVICE_FEE || rows[i].lineItem == INSURANCE_ADMIN_FEE||rows[i].lineItem == "Service Fee"){
                    if(rows[i].lineItem == INSURANCE_ADMIN_FEE){
                        insurAdminFee = rows[i].amount;                       
                    }
                    continue;
                }
                tAmount = tAmount+Number(rows[i].amount);//+Number(rows[1].amount)+Number(rows[2].amount)+Number(rows[3].amount)+Number(rows[4].amount)+Number(rows[5].amount);
            }
            let totServiceFee:any = tAmount*(Number(rServiceFee)/100);
            totServiceFee = Number(insurAdminFee)+Number(totServiceFee);
            if(totServiceFee>minServiceFee){
                totServiceFee = totServiceFee.toFixed(2);
            }else{
                totServiceFee = minServiceFee;
            }
            rows[rows.length-6].amount = totServiceFee;
            /*tAmount = Number(rows[0].amount)+Number(rows[1].amount)+Number(rows[2].amount)+Number(rows[3].amount)+Number(rows[4].amount)+Number(rows[5].amount);
            tAmount = tAmount*(Number(rServiceFee)/100);
            if(tAmount>minServiceFee){
                rows[6].amount = tAmount.toFixed(2);
            }else{
                rows[6].amount = minServiceFee;
            }*/
        }
    }

    let calculatePhysicalDamageInsurance = (days:any,rows:any)=>{
        console.log(days,rows);
        let endDate = new Date(actuvalEndDate);
        let stDate = new Date(actuvalStartDate);
        let diffDays =  CommonUtil.getDays(stDate,endDate);

        let liabilityAmount:any = 0;
        let physicalDamageAmount:any = 0;
        let totalAmount:any = 0;
        rows.forEach((item:any,idx:number)=>{
            if(item.lineItem == PHYSICAL_DAMAGE_INSURANCE) {
                rows[idx].excessDays = Number(days);//(Number(diffDays)+Number(days));
                let totAmount:any = Number(days) * Number(rows[idx].perDayInsurance);//Number(rows[idx].excessDays)*Number(rows[idx].perDayInsurance);
                if(totAmount){
                    rows[idx].amount = totAmount.toFixed(2);
                }else{
                    rows[idx].amount = "0";
                }
               //Number(rows[idx].excessDays)*Number(rows[idx].perDayInsurance);
            }
        });
        rows.forEach((item:any,idx:number)=>{

            if(item.lineItem == LIABILITY_INSURANCE || item.lineItem == PHYSICAL_DAMAGE_INSURANCE || item.lineItem == INSURANCE_ADMIN_FEE) {
                if(item.lineItem == LIABILITY_INSURANCE){
                    liabilityAmount = Number(rows[idx].amount);
                }

                if(item.lineItem == PHYSICAL_DAMAGE_INSURANCE){
                    physicalDamageAmount = Number(rows[idx].amount);
                }

                if(item.lineItem == INSURANCE_ADMIN_FEE){
                    totalAmount = liabilityAmount+physicalDamageAmount;
                    totalAmount = Number(totalAmount)*(INSUR_ADMIN_FEE/100);
                    if(totalAmount){
                        totalAmount = totalAmount.toFixed(2);
                        let adminFee:any = Number(String(defaultCurrency||defCurrency).toUpperCase().indexOf("USD") !==-1 ?usInsuranceRate:canadaInsuranceRate);
                        rows[idx].amount = adminFee;
                    }
                }

            }

        });

    }

    let calculateLiabilityInsurance = (days:any,rows:any)=>{
        console.log(days,rows);
        let endDate = new Date(actuvalEndDate);
        let stDate = new Date(actuvalStartDate);
        let diffDays =  CommonUtil.getDays(stDate,endDate);

        let liabilityAmount:any = 0;
        let physicalDamageAmount:any = 0;
        let totalAmount:any = 0;
        rows.forEach((item:any,idx:number)=>{
            if(item.lineItem == LIABILITY_INSURANCE) {
                rows[idx].excessDays = Number(days);//(Number(diffDays)+Number(days));
                let totAmount:any = Number(days)*Number(rows[idx].perDayInsurance);//Number(rows[idx].excessDays)*Number(rows[idx].perDayInsurance);
                if(totAmount){
                    rows[idx].amount = totAmount.toFixed(2);
                }else{
                    rows[idx].amount = "0";
                }
                //Number(rows[idx].excessDays)*Number(rows[idx].perDayInsurance);
            }
        });
        rows.forEach((item:any,idx:number)=>{

            if(item.lineItem == LIABILITY_INSURANCE || item.lineItem == PHYSICAL_DAMAGE_INSURANCE || item.lineItem == INSURANCE_ADMIN_FEE) {
                if(item.lineItem == LIABILITY_INSURANCE){
                    liabilityAmount = Number(rows[idx].amount);
                }

                if(item.lineItem == PHYSICAL_DAMAGE_INSURANCE){
                    physicalDamageAmount = Number(rows[idx].amount);
                }

                if(item.lineItem == INSURANCE_ADMIN_FEE){
                    totalAmount = liabilityAmount+physicalDamageAmount;
                    totalAmount = Number(totalAmount)*(INSUR_ADMIN_FEE/100);
                    if(totalAmount){
                        totalAmount = totalAmount.toFixed(2);
                        let adminFee:any = Number(String(defaultCurrency||defCurrency).toUpperCase().indexOf("USD") !==-1 ?usInsuranceRate:canadaInsuranceRate);
                        rows[idx].amount = adminFee;
                    }
                }

            }

        });

    }

    let calculatevHubServiceFee = (days:any,rows:any)=>{
        console.log(days,rows);
        let endDate = new Date(actuvalEndDate);
        let stDate = new Date(actuvalStartDate);
        let diffDays =  CommonUtil.getDays(stDate,endDate);
        rows.forEach((item:any,idx:number)=>{
            if(item.lineItem == VHUB_SERVICE_FEE) {
               // rows[idx].amount = (Number(diffDays)+Number(days));
                rows[idx].excessDays = (Number(diffDays))//+Number(days);
                let totAmount:any = Number(rows[idx].excessDays)*Number(rOwnerServiceFee);
                if(totAmount){
                    rows[idx].amount = totAmount.toFixed(2);
                }else{
                    rows[idx].amount = "0";
                }
                //Number(rows[idx].excessDays)*Number(rows[idx].perDayInsurance);
            }
        })
    }


    const onChangeGridInputs = (event: any,data:any, field:String,name:string|undefined)=>{
            event.preventDefault();
            //if(data.lineItem === 'Service Fee') return;
             focusField = field;
             focusFieldName = name;
            lineNo = data.line;
           const e = CommonUtil.validateInput(event);
            let rows = [...rowsData];

            let findRow = rows.findIndex((row)=> row.lineItem === data.lineItem);
            console.log(findRow);            
            let grandTotal:any = 0;
            if(data.lineItem === DISTANCE){
                if(e && e.target){
                    let getValue = e.target.value.toString();

                    if(getValue?.length === 1 && getValue === '.'){
                        getValue = `0.`;
                    }
                    let endDate = new Date(actuvalEndDate);
                    let stDate = new Date(actuvalStartDate);
                    let days =  CommonUtil.getDays(stDate,endDate);
                    let disAmount = 0;
                    if(listingType == RENTAL){
                        disAmount = Number(rDistanceRate);
                    }else{
                        disAmount = rPricePerKm;
                    }
                    let distancePrice:any = Number(disAmount)*Number(getValue);//*days;
                    totalDistance = getValue;
                    if(distancePrice){
                        distancePrice = distancePrice.toFixed(2);
                    }

                    rows[findRow].amount = `${distancePrice}`
                    editableFields.totalDistance = getValue;
                    // setEditableFields((prev:any)=>{
                    //     return {...prev, totalDistance:getValue}
                    // })

                    calculateServiceFee(rows);
                    const total = calculateTotal(findRow);
                    grandTotal = Number(total) + Number(distancePrice);
                    rows[findRow][`${field}`] = getValue;
                    // rows[3].amount = 400;
                    //console.log(rows)
                }


            }else if(data.lineItem === ENGINE_UNIT_USAGE){
                let amout = Number(e.target.value)*Number(rEngineRate);//calculateTotal(findRow);
                editableFields.engineUnitUsageHours = e.target.value;//Number(e.target.value)*Number(rEngineRate);
                // setEditableFields((prev:any)=>{
                //     return {...prev, engineUnitUsageHours:e.target.value}
                // })
                let subtotal:any = amout.toFixed(2);
                rows[findRow][`${field}`] = e.target.value;
                rows[findRow].amount = `${subtotal}`;
                calculateServiceFee(rows);
                const total = calculateTotal(findRow);
                grandTotal = Number(total) + Number(amout);
            }else if(data.lineItem === EXCESS_DAYS){
                let eValue:any = e.target.value;
                if(eValue && eValue.indexOf(".")>=0){
                    eValue = eValue.substring(0,eValue.indexOf("."));
                }
                let totalPrice:any = Number(eValue) * Number(rAdditionalRate);                
                if(totalPrice){
                    totalPrice = totalPrice.toFixed(2);
                }
                rows[findRow].amount = `${totalPrice}`;
                if(rListingType == REPOSITION || rListingType == RENTAL){
                   // calculateInsurance(eValue,rows);
                    //calculatevHubServiceFee(eValue,rows);
                }
                calculateServiceFee(rows);
                const total = calculateTotal(findRow);
                grandTotal = Number(total) + Number(totalPrice);
                editableFields.excessDays = eValue;
                setRepExcessDays(eValue);
                // setEditableFields((prev:any)=>{
                //     return {...prev, excessDays:e.target.value}
                // })
                rows[findRow][`${field}`] = eValue;//e.target.value;
            }else if(data.lineItem === PHYSICAL_DAMAGE_INSURANCE){
                let eValue:any = e.target.value;
                if(eValue && eValue.indexOf(".")>=0){
                    eValue = eValue.substring(0,eValue.indexOf("."));
                }
                let totalPrice:any = Number(eValue) * Number(data.perDayInsurance);
                // let totalPrice:any = Number(eValue) * Number(rAdditionalRate); change to perDayInsurance
                if(data.perDayInsurance === 0){
                    totalPrice = 0;
                }
                if(totalPrice){
                    totalPrice = totalPrice.toFixed(2);
                }
                //rows[findRow].amount = `${totalPrice}`;
                if(rListingType == REPOSITION || rListingType == RENTAL){
                    calculatePhysicalDamageInsurance(eValue,rows);
                    calculatevHubServiceFee(eValue,rows);
                }
                calculateServiceFee(rows);
                const total = calculateTotal(findRow);
                grandTotal = Number(total) + Number(totalPrice);
                editableFields.physicalInsuranceDays = eValue;
                //editableFields.excessDays = eValue;
                // setEditableFields((prev:any)=>{
                //     return {...prev, excessDays:e.target.value}
                // })
                rows[findRow][`${field}`] = eValue;//e.target.value;
            }else if(data.lineItem === LIABILITY_INSURANCE){
                let eValue:any = e.target.value;
                if(eValue && eValue.indexOf(".")>=0){
                    eValue = eValue.substring(0,eValue.indexOf("."));
                }
                let totalPrice:any = Number(eValue) * Number( Number(data.perDayInsurance));
                // let totalPrice:any = Number(eValue) * Number(rAdditionalRate); change the with  Number(data.perDayInsurance)
                if(data.perDayInsurance === 0){
                    totalPrice = 0;
                }
                if(totalPrice){
                    totalPrice = totalPrice.toFixed(2);
                }
                //rows[findRow].amount = `${totalPrice}`;
                if(rListingType == REPOSITION || rListingType == RENTAL){
                    calculateLiabilityInsurance(eValue,rows);
                    calculatevHubServiceFee(eValue,rows);
                }
                calculateServiceFee(rows);
                const total = calculateTotal(findRow);
                grandTotal = Number(total) + Number(totalPrice);
                editableFields.liabilityInsuranceDays = eValue;
                //editableFields.excessDays = eValue;
                // setEditableFields((prev:any)=>{
                //     return {...prev, excessDays:e.target.value}
                // })
                rows[findRow][`${field}`] = eValue;//e.target.value;
            }else if(data.lineItem === SERVICE_FEE){
                let lengthInput = event.target.value;
                if(lengthInput){
                    lengthInput = lengthInput.substring(1);
                    rows[findRow].amount = `${lengthInput.trimLeft()}`;
                    const total = calculateTotal(findRow);
                    grandTotal = Number(total) + Number(lengthInput.trimLeft());
                    editableFields.serviceFee = lengthInput.trimLeft();
                    // setEditableFields((prev:any)=>{
                    //     return {...prev, serviceFee:lengthInput.trimLeft()}
                    // })
                    rows[findRow][`${field}`] = lengthInput.trimLeft();
                }
            }else if(data.lineItem === CONCIERGEFEEOWNER){
                let lengthInput = event.target.value;
                if(lengthInput){
                    lengthInput = lengthInput.substring(1);
                    rows[findRow].conciergeFeeOwner = `${lengthInput.trimLeft()}`;
                    const total = calculateTotal(findRow);
                    grandTotal = Number(total) + Number(lengthInput.trimLeft());
                    editableFields.conciergeFeeOwner = lengthInput.trimLeft();
                    // setEditableFields((prev:any)=>{
                    //     return {...prev, serviceFee:lengthInput.trimLeft()}
                    // })
                    rows[findRow][`${field}`] = lengthInput.trimLeft();
                }
            }else if(data.lineItem === CONCIERGEFEERENTER){
                let lengthInput = event.target.value;
                if(lengthInput){
                    lengthInput = lengthInput.substring(1);
                    rows[findRow].conciergeFeeRenter = `${lengthInput.trimLeft()}`;
                    const total = calculateTotal(findRow);
                    grandTotal = Number(total) + Number(lengthInput.trimLeft());
                    editableFields.conciergeFeeRenter = lengthInput.trimLeft();
                    // setEditableFields((prev:any)=>{
                    //     return {...prev, serviceFee:lengthInput.trimLeft()}
                    // })
                    rows[findRow][`${field}`] = lengthInput.trimLeft();
                }

            }else if(data.lineItem === NUMBER_OF_DAYS){
                let getValue = e.target.value.toString();
                if(getValue?.length === 1 && getValue === '.'){
                    getValue = `0.`;
                }
                let totalVal:any = rows[findRow].perDayInsurance*getValue;
                totalVal = totalVal.toFixed(2);
                console.log(data.lineItem,getValue);
                rows[findRow].excessDays = getValue;
                rows[findRow].amount = totalVal;
                calculateServiceFee(rows);
                const total = calculateTotal(findRow);
                grandTotal = Number(total) + Number(totalVal);
            }

            if(grandTotal){
                grandTotal = grandTotal.toFixed(2);
            }

            rows[rows.length - 1].amount = grandTotal;
            //console.log(rows);

        //BusyLoader.showLoader();


        setRowsData([]);
        setTimeout(()=>{
            setRowsData(rows);
           // BusyLoader.hideLoader();

        })

    }

     const loadDisplayGridRows = () => {
        // setTimeout(()=>{
            displayGridRows({distanceApproval,engineUsageApproval,excessDaysApproval,movePriceApproval,rentalDurationApproval,serviceFeeApproval,
            liabilityInsuranceApproval,physicalDamageInsuranceApproval,vhubServiceFeeArroval,insuranceAdministrationFeeApproval});            
        // })
        
    }
    const isAdditionRateforExcessDate = ()=>{
        if(listingType === RENTAL){
        }else{
            if(Number(repExcessDays||0) > 0 ){
                if(Number(addtionalPrice||0) <= 0 ){
                    ToastManager.showToast("Please provide Additional Rate /day value for excess days","Error");
                    return true
                }
            }
        }
        return false;
    }

    let onSaveData = ()=>{
        if(isAdditionRateforExcessDate())
            return;
        //rowsData
        let reqObj:any = {};
        reqObj.reservationId = CommonUtil.SELECT_RESERVATION_ID;

        reqObj.customerPo = ownerPo;
        reqObj.cglRate = custCost;
        reqObj.ownerPo = ownerPo;
        reqObj.renterPo = renterPo;
        reqObj.ownerGlRate = ownerCost;
        reqObj.notes = notes;
        reqObj.customerNotes = custNotes;
        if(localStorage.getItem('REPROCESS_PREXTRA') === 'Y')
            reqObj.reProcess = 'Y';
        localStorage.removeItem('REPROCESS_PREXTRA')

        if(listingType == RENTAL){
            reqObj.dailyRate = rDailyRate;
            reqObj.monthlyRate = monthlyRateNew;
            reqObj.weeklyRate = weeklyRateNew;
            reqObj.distanceRate = distanceRate;
            reqObj.engineRate = engineRate;

            /*reqObj.rentalDurationApproval = (rowsData[0] && rowsData[0].approval)?"Y":"N";
            reqObj.distanceApproval = (rowsData[1] && rowsData[1].approval)?"Y":"N";
            reqObj.engineUsageApproval = (rowsData[2] && rowsData[2].approval)?"Y":"N";
            reqObj.liabilityInsuranceApproval = (rowsData[3] && rowsData[3].approval)?"Y":"N";
            reqObj.physicalDamageInsuranceApproval = (rowsData[4] && rowsData[4].approval)?"Y":"N";
            reqObj.liabilityInsuranceAmountPerDay = (rowsData[3] && rowsData[3].perDayInsurance)?rowsData[3].perDayInsurance:"";
            reqObj.physicalDamageInsuranceAmountPerDay = (rowsData[4] && rowsData[4].perDayInsurance)?rowsData[4].perDayInsurance:"";
            reqObj.vhubServiceFeeApproval = (rowsData[5] && rowsData[5].approval)?"Y":"N";
            reqObj.vhubServiceFee = (rowsData[5] && rowsData[5].amount)?rowsData[5].amount:"";

            reqObj.insuranceAdministrationFeeApproval = (rowsData[6] && rowsData[6].approval)?"Y":"N";
            reqObj.insuranceAdminFee = (rowsData[6] && rowsData[6].amount)?rowsData[5].amount:"";

            reqObj.serviceFeeApproval = (rowsData[7] && rowsData[7].approval)?"Y":"N";*/

            rowsData.forEach((row:any,idx:number)=>{
                if(row.lineItem == RENTAL_DURATION){
                    reqObj.rentalDurationApproval = (row.approval)?"Y":"N";
                }else if(row.lineItem == DISTANCE){
                    reqObj.distanceApproval = (row.approval)?"Y":"N";
                }else if(row.lineItem == ENGINE_UNIT_USAGE){
                    reqObj.engineUsageApproval = (row.approval)?"Y":"N";
                }else if(row.lineItem == LIABILITY_INSURANCE){
                    reqObj.liabilityInsuranceApproval = (row.approval)?"Y":"N";
                    reqObj.liabilityInsuranceAmountPerDay = (row.perDayInsurance)?row.perDayInsurance:"";
                    reqObj.liabilityInsuranceNoOfDays = (row.totalDays)?row.totalDays:"";
                }else if(row.lineItem == PHYSICAL_DAMAGE_INSURANCE){
                    reqObj.physicalDamageInsuranceApproval = (row.approval)?"Y":"N";
                    reqObj.physicalDamageInsuranceAmountPerDay = (row.perDayInsurance)?row.perDayInsurance:"";
                    reqObj.physicalDamageInsuranceNoOfDays = (row.totalDays)?row.totalDays:"";
                }else if(row.lineItem == VHUB_SERVICE_FEE){
                    reqObj.vhubServiceFeeApproval = (row.approval)?"Y":"N";
                    reqObj.vhubServiceFee = (row.amount)?row.amount:"";
                }else if(row.lineItem == INSURANCE_ADMIN_FEE){
                    reqObj.insuranceAdministrationFeeApproval = (row.approval)?"Y":"N";
                    reqObj.insuranceAdminFee = (row.amount)?row.amount:"";
                }else if(row.lineItem == SERVICE_FEE){
                    reqObj.serviceFeeApproval = (row.approval)?"Y":"N";
                }
            });


            reqObj.startDate = actuvalStartDate;//CommonUtil.getDateString(actuvalStartDate);//CommonUtil.getDate()actuvalStartDate;
            reqObj.endDate = actuvalEndDate;//CommonUtil.getDateString(actuvalEndDate);//actuvalEndDate;
            reqObj.engineUnitUsageHours = editableFields.engineUnitUsageHours ? editableFields.engineUnitUsageHours : '';
            reqObj.serviceFee = editableFields.serviceFee ? editableFields.serviceFee : '';
            reqObj.conciergeFeeRenter = editableFields.conciergeFeeRenter ? editableFields.conciergeFeeRenter : '';
            reqObj.conciergeFeeOwner = editableFields.conciergeFeeOwner ? editableFields.conciergeFeeOwner : '';
            reqObj.totalDistance = editableFields.totalDistance ? editableFields.totalDistance : '';
            //reqObj.customerNotes = custNotes;
            reqObj.pricePerKm = "";

            //reqObj.activeDuration = (rowsData[3] && rowsData[3].totalDays)?rowsData[3].totalDays:"";;

        }else{
            reqObj.movePrice = rMoveRate;
            reqObj.additionalPrice = rAdditionalRate;
            //reqObj.customerPo = custPo;
            //reqObj.cglRate = custCost;
            //reqObj.notes = notes;
            //reqObj.customerNotes = custNotes;
            reqObj.movePrice = movePrice;
            reqObj.additionalPrice = addtionalPrice;

           /* reqObj.movePriceApproval = (rowsData[0] && rowsData[0].approval)?"Y":"N";
            reqObj.excessDaysApproval = (rowsData[1] && rowsData[1].approval)?"Y":"N";
            reqObj.distanceApproval = (rowsData[2] && rowsData[2].approval)?"Y":"N";
            reqObj.engineUsageApproval = (rowsData[3] && rowsData[3].approval)?"Y":"N";
            reqObj.liabilityInsuranceApproval = (rowsData[4] && rowsData[4].approval)?"Y":"N";
            reqObj.physicalDamageInsuranceApproval = (rowsData[5] && rowsData[5].approval)?"Y":"N";
            reqObj.liabilityInsuranceAmountPerDay = (rowsData[4] && rowsData[4].perDayInsurance)?rowsData[4].perDayInsurance:"";
            reqObj.physicalDamageInsuranceAmountPerDay = (rowsData[5] && rowsData[5].perDayInsurance)?rowsData[5].perDayInsurance:"";
            reqObj.vhubServiceFee = (rowsData[6] && rowsData[6].amount)?rowsData[6].amount:"";
            reqObj.vhubServiceFeeApproval = (rowsData[6] && rowsData[6].approval)?"Y":"N";
            reqObj.insuranceAdministrationFeeApproval = (rowsData[7] && rowsData[7].approval)?"Y":"N";
            reqObj.insuranceAdminFee = (rowsData[7] && rowsData[7].amount)?rowsData[5].amount:"";
            reqObj.serviceFeeApproval = (rowsData[8] && rowsData[8].approval)?"Y":"N";*/

            rowsData.forEach((row:any,idx:number)=>{
                if(row.lineItem == REPOSITION_SERVICE_PRICE){
                    reqObj.movePriceApproval = (row.approval)?"Y":"N";
                }if(row.lineItem == EXCESS_DAYS){
                    reqObj.excessDaysApproval = (row.approval)?"Y":"N";
                }else if(row.lineItem == DISTANCE){
                    reqObj.distanceApproval = (row.approval)?"Y":"N";
                }else if(row.lineItem == ENGINE_UNIT_USAGE){
                    reqObj.engineUsageApproval = (row.approval)?"Y":"N";
                }else if(row.lineItem == LIABILITY_INSURANCE){
                    reqObj.liabilityInsuranceApproval = (row.approval)?"Y":"N";
                    reqObj.liabilityInsuranceAmountPerDay = (row.perDayInsurance)?row.perDayInsurance:"";
                    reqObj.liabilityInsuranceNoOfDays = (row.excessDays)?row.excessDays:"";
                }else if(row.lineItem == PHYSICAL_DAMAGE_INSURANCE){
                    reqObj.physicalDamageInsuranceApproval = (row.approval)?"Y":"N";
                    reqObj.physicalDamageInsuranceAmountPerDay = (row.perDayInsurance)?row.perDayInsurance:"";
                    reqObj.physicalDamageInsuranceNoOfDays = (row.excessDays)?row.excessDays:"";
                }else if(row.lineItem == VHUB_SERVICE_FEE){
                    reqObj.vhubServiceFeeApproval = (row.approval)?"Y":"N";
                    reqObj.vhubServiceFee = (row.amount)?row.amount:"";
                }else if(row.lineItem == INSURANCE_ADMIN_FEE){
                    reqObj.insuranceAdministrationFeeApproval = (row.approval)?"Y":"N";
                    reqObj.insuranceAdminFee = (row.amount)?row.amount:"";
                }else if(row.lineItem == SERVICE_FEE){
                    reqObj.serviceFeeApproval = (row.approval)?"Y":"N";
                }
            });

            reqObj.engineUnitUsageHours = editableFields.engineUnitUsageHours ? editableFields.engineUnitUsageHours : '';
            reqObj.serviceFee = editableFields.serviceFee ? editableFields.serviceFee : '';
            reqObj.totalDistance = editableFields.totalDistance ? editableFields.totalDistance : '';
            reqObj.excessDays = editableFields.excessDays ? editableFields.excessDays : '';
            reqObj.pricePerKm = pricePerKm;
            reqObj.insuranceAmountPerDay = (rowsData[4] && rowsData[4].perDayInsurance)?rowsData[4].perDayInsurance:"";
            reqObj.activeDuration = (rowsData[4] && rowsData[4].excessDays)?rowsData[4].excessDays:"";
            reqObj.conciergeFeeRenter = editableFields.conciergeFeeRenter ? Number(editableFields.conciergeFeeRenter) : 0;
            reqObj.conciergeFeeOwner = editableFields.conciergeFeeOwner ? Number(editableFields.conciergeFeeOwner) : 0;
            reqObj.platformFeesOwner = Number(pfOwner);
            reqObj.platformFeesRenter = Number(pfRenter);
            if(deactivationDateRef.current){
                let edDate = deactivationDateRef.current.flatpickr.selectedDates[0];
                reqObj.endDate = CommonUtil.getReservationRepositionDateString(edDate);
            }
        }
        reqObj.sendToPrextra = operation === 'Save Prextra' ? 'Y' : 'N';

         console.log(reqObj);
        var invoiceAPI:string = "api/reservation/processInvoice";
        NetworkManager.sendJsonRequest(invoiceAPI,reqObj,"","POST",true,onInvoiceReportSuccess,onLoginError);
    }

    let onInvoiceReportSuccess = (dataObj:any)=>{
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                if(operation == PRINT_PDF){
                    onGeneratePrintPDF();
                }else{
                    if(operation == SAVE_DB){
                        ToastManager.showToast(CommonUtil.getMessageText(Constants.SAVED_SUCCESSFULLY,"Saved Successfully"), 'Info');
                    }else{
                        setPrextraDisable(true);
                        ToastManager.showToast(CommonUtil.getMessageText(Constants.SEND_PREXTRA,"Saved & send to Prextra"), 'Info');
                    }
                }
            }
        }
    }
    let onClickSave = ()=>{
        operation = SAVE_DB;
        onSaveData();
    }
    let onClickSavePrextra = ()=>{
        operation = SAVE_Prextra;
        onSaveData();
    }
    let printPDF  = ()=>{
        operation = PRINT_PDF;
        onSaveData();
    }

    let onGeneratePrintPDF = async ()=>{
        BusyLoader.showLoader();
        var myHeaders = new Headers()
        myHeaders.append('Content-Type', 'application/json')
        if (localStorage.getItem('token')) {
            var token = 'Bearer ' + localStorage.getItem('token')
            myHeaders.append('Authorization', token);
        }
        await fetch(NetworkManager.serverPath + "api/download/invoice?reservationCode=" + reservationCode, {method:'GET', headers:myHeaders })
            .then((response:any) => {
                response.blob().then((blob:any) => {
                    BusyLoader.hideLoader();
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `reservation_${reservationCode}.pdf`;
                    a.click();
                });

            }).catch((error)=>{ BusyLoader.hideLoader();})
    }

    let onLoginError = (err:any)=>{
        console.log(err);
    }

    const getRowStyle = (params :any) => params.node.lastChild ? {'fontWeight': 'bold' } : null;

    let onChangeRevisedDate = (dateValue:any)=>{
        if(deactivationDateRef.current){
            let strDate:string = CommonUtil.getDateTime(dateValue);
            actuvalEndDate = strDate;
            //gridUpdated = false;
            loadDisplayGridRows();
        }
    }

    const navigateReservation = () => {
          localStorage.setItem("ReservationTab","true");
            let equipId:string = CommonUtil.SELECT_EQUIP_ID;
            let reservationId:string = CommonUtil.SELECT_RESERVATION_ID;
            let reservationAssetType:string = CommonUtil.EQUIP_RESERVE_ASSET_TYPE;
            let isGeneric:string = CommonUtil.EQUIP_RESERVE_IS_GENERIC;
            let equipType:string = CommonUtil.EQUIP_RESERVE_EQUIPMENT_TYPE;
             let availId:string = CommonUtil.SELECT_EQUIP_AVAIL_ID;

            let siteOrigin:string = window.location.origin;
            siteOrigin = siteOrigin+"/?requestor=reservationview&equipid="+equipId+"&token="+ localStorage.getItem("token");
            siteOrigin = siteOrigin+"&reservationId="+reservationId;
            siteOrigin = siteOrigin+"&assetType="+reservationAssetType;
            siteOrigin = siteOrigin+"&equipType="+equipType;
            siteOrigin = siteOrigin+"&generic="+isGeneric;
             siteOrigin = siteOrigin+"&availId="+availId;

            var newTab = document.createElement('a');
            newTab.href = siteOrigin;
            newTab.setAttribute('target', '_blank');
            newTab.click();
    }


    return(
        <>
            <div className="page-content-wrapper invoice-process">
                <div className="container-fluid" style={{paddingLeft:"15px",paddingRight:"15px"}}>
                    <div className="row mt-3">
                        <div className="col-md-4">
                            <section>
                                <div className="row">
                                    <div className="col-12">
                                        <h6 className="mb-2">{CommonUtil.getMessageText(Constants.OWNER_DETAILS,"Owner Details")}</h6>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <strong className="mb-1 d-block">{ownercompanyName}</strong>
                                            </div>
                                            <div className="col-5">{CommonUtil.getMessageText(Constants.CONTACT_NAME,"Contact Name")}</div>
                                            <div className="col-7 colon1">{ownercontactName}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5">{CommonUtil.getMessageText(Constants.CONTACT_NUMBER,"Contact Number")}</div>
                                            <div className="col-7 colon1">{ownerphoneNum}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5">{CommonUtil.getMessageText(Constants.EMAIL_ID,"Email Id")}</div>
                                            <div className="col-7 colon1">{ownerphoneNum}</div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="col-md-4">
                            <section>
                                <div className="row">
                                    <div className="col-12">
                                        <h6 className="mb-2">{CommonUtil.getMessageText(Constants.RENTER_DETAILS,"Renter Details")}</h6>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <strong className="mb-1 d-block">{companyName}</strong>
                                            </div>
                                            <div className="col-5">{CommonUtil.getMessageText(Constants.CONTACT_NAME,"Contact Name")}</div>
                                            <div className="col-7 colon1">{contactName}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5">{CommonUtil.getMessageText(Constants.CONTACT_NUMBER,"Contact Number")}</div>
                                            <div className="col-7 colon1">{phoneNum}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5">{CommonUtil.getMessageText(Constants.EMAIL_ID,"Email Id")}</div>
                                            <div className="col-7 colon1">{mail}</div>
                                        </div>


                                    </div>
                                </div>
                            </section>

                        </div>
                        <div className="col-md-4">
                            <section style={{paddingBottom:"5px"}}>
                                <div className="row">
                                    <div className="col-12">
                                        <h6 className="mb-2">{CommonUtil.getMessageText(Constants.RESERVATION,"Reservation")}</h6>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">                                           
                                            <div className="col-5">{CommonUtil.getMessageText(Constants.RESERVATION_CODE,"Reservation Code")}</div>
                                            <div className="col-7 colon1">{reservationCode}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5">{CommonUtil.getMessageText(Constants.TRAILER_LEN,"Trailer Number")}</div>
                                            <div className="col-7 colon1">{CommonUtil.SELECT_EQUIP_ID}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5">{CommonUtil.getMessageText(Constants.VIN,"VIN")}</div>
                                            <div className="col-7 colon1">{vin}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5">{CommonUtil.getMessageText(Constants.EQUIP_TYPE,"Equipment Type")}</div>
                                            <div className="col-7 colon1">{CommonUtil.EQUIP_RESERVE_EQUIPMENT_TYPE}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5">{CommonUtil.getMessageText(Constants.RESERVATION_TYPE,"Reservation Type")}</div>
                                            <div className="col-7 colon1">{listingType}</div>
                                        </div>

                                    </div>
                                </div>
                            </section>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <section>

                                <div className="row">
                                    <div className="col-12">
                                        <h6 className="mb-2">{CommonUtil.getMessageText(Constants.RESERVATION_DETAILS,"Reservation Details")}</h6>
                                    </div>

                                    <div className="col-sm-6 col-lg-3">
                                        <div className="row">
                                            <div className="col-7">Reservation: <a style={{ color:'var(--bs-link-color)' }} onClick={navigateReservation} >{reservationCode}</a></div>
                                            <div className="col-5  text-center po-button" style={{display:"none"}}>
                                                <button type="button" className="btn btn-primary waves-effect waves-light">{reservationStatus}
                                                </button>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-sm-6 col-lg-3">
                                        <div className="row">
                                            <div className="col-12">{CommonUtil.getMessageText(Constants.RESERVATION_TYPE,"Reservation Type")}: {listingType}</div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="row">

                                            <div className="col-sm-6">
                                                <div className="row">
                                                    <div className="col-12">{CommonUtil.getMessageText(Constants.ACTIVATION_DATE,"Activation Date")}:{activationDate}
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-sm-6">
                                                <div className="row">
                                                    <div className="col-12">{CommonUtil.getMessageText(Constants.DE_ACTIVATION_DATE,"Deactivation Date")}: {deActivationDate}
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>

                                </div>

                                <br />
                                    <div className="row">
                                        <div className="col-sm-4 col-lg-3 mb-2">
                                            {CommonUtil.getMessageText(Constants.CURRENCY,"Currency")}: <input type="text" className="d-inline-block width-100 pl-2"
                                                             required value={defCurrency} disabled={true} />
                                        </div>
                                        <div className="col-sm-8 col-lg-3 mb-2">
                                            <div className="row">
                                            <div className="col-12 mb-1" style={{display:(listingType == RENTAL?"":"none")}}>
                                                {dailyRatePlan ==="Daily" ?<>
                                                {CommonUtil.getMessageText(Constants.DAILY_RATE,"Daily Rate")}: <input type="text" className="d-inline-block width-100"
                                                                required value={dailyRate}  onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => {onChangeDailyRate(e)}}/> /{CommonUtil.getMessageText(Constants.DAY,"DAY")}
                                                </>:
                                                dailyRatePlan ==="Weekly" ?<>
                                                {CommonUtil.getMessageText(Constants.WEEKLY_RATE,"Weekly Rate")}: <input type="text" className="d-inline-block"
                                                required value={weeklyRateNew}  onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => {onChangeWeeklyRate(e)}}/> /{CommonUtil.getMessageText(Constants.WEEK,"WEEK")}
                                                </>:
                                                dailyRatePlan ==="Monthly" ?<>
                                                {CommonUtil.getMessageText(Constants.MONTHLY_RATE,"Monthly Rate")}: <input type="text" className="d-inline-block"
                                                                   required value={monthlyRateNew}  onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => {onChangeMonthlyRate(e)}}/> /{CommonUtil.getMessageText(Constants.MONTH,"MONTH")}
                                                </>:''
                                                }                                                
                                                </div>                         
                                          
                                            </div>
                                        
                                        </div>
                                        
                                    {/* <div className="col-sm-6 col-lg-3 mb-2" style={{display:(listingType == RENTAL?"":"none")}}>
                                            {CommonUtil.getMessageText(Constants.DAILY_RATE,"Weekly Rate")}: <input type="text" className="d-inline-block width-100 pl-2"
                                                               required value={dailyRate}  onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => {onChangeDailyRate(e)}}/> /{CommonUtil.getMessageText(Constants.DAY,"DAY")}
                                    </div>
                                    <div className="col-sm-6 col-lg-3 mb-2" style={{display:(listingType == RENTAL?"":"none")}}>
                                            {CommonUtil.getMessageText(Constants.DAILY_RATE,"Monthly Rate")}: <input type="text" className="d-inline-block width-100 pl-2"
                                                               required value={dailyRate}  onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => {onChangeDailyRate(e)}}/> /{CommonUtil.getMessageText(Constants.DAY,"DAY")}
                                        </div> */}
                                        <div className="col-sm-6 col-lg-3 mb-2" style={{display:(listingType == RENTAL?"":"none")}}>
                                            {CommonUtil.getMessageText(Constants.DISTANCE_RATE,"Distance Rate")}: <input type="text" className="d-inline-block width-100 pl-2"
                                                                  required value={distanceRate}   onChange={(e) => {onChangeDistanceRate(e)}}/> /{CommonUtil.getMessageText(Constants.DAY,"DAY")}
                                        </div>

                                        <div className="col-sm-6 col-lg-3 mb-2" style={{display:(listingType != RENTAL?"":"none")}}>
                                            {CommonUtil.getMessageText(Constants.MOVE_RATE,"Move Rate")}: <input type="text" className="d-inline-block width-100 pl-2"
                                                                  required value={movePrice}   onChange={(e) => {onChangeMoveRate(e)}}/>
                                        </div>
                                        <div className="col-sm-6 col-lg-3 mb-2" style={{display:(listingType != RENTAL?"":"none")}}>
                                            {CommonUtil.getMessageText(Constants.ADDITIONAL_RATE,"Additional Rate")}: <input type="text" className="d-inline-block width-100 pl-2"
                                                              required value={addtionalPrice}   onChange={(e) => {onChangeAdditionalRate(e)}}/> /{CommonUtil.getMessageText(Constants.DAY,"DAY")}
                                        </div>
                                        <div className="col-sm-6 col-lg-3 mb-2" style={{marginTop:"-10px",display:(listingType != RENTAL?"":"none")}}>
                                            {CommonUtil.getMessageText(Constants.REVISED_DE_ACTIVATION_DATE,"Revised Deactivation Date")}:
                                            <Flatpickr ref={deactivationDateRef} options={dateOptions} placeholder="Deactivation Date" className={"form-control flat-picker"}
                                                       onChange={onChangeRevisedDate}/>
                                        </div>

                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-sm-6 col-lg-3 mb-2" style={{display:(listingType != RENTAL?"":"none")}}>
                                            {CommonUtil.getMessageText(Constants.PRICE_PER_KM,"Price per km")}: <input type="text" className="d-inline-block width-100 pl-2"
                                                                                                                       required value={pricePerKm}   onChange={(e) => {onChangePricePerKM(e)}}/>
                                        </div>
                                        <div className="col-sm-6 col-lg-3 mb-2" style={{display:(listingType != RENTAL?"":"none")}}>
                                            {CommonUtil.getMessageText(Constants.ACTUAL_REPOSITION_DAYS,"Actual Reposition Days")}: {noOfDays} {CommonUtil.getMessageText(Constants.DAYS,"days")}
                                        </div>
                                        <div className="col-sm-6 col-lg-3 mb-2" style={{display:(listingType != RENTAL?"":"none")}}>
                                            {CommonUtil.getMessageText(Constants.EXCESS_DAYS,"Excess Days")}: {repExcessDays} {CommonUtil.getMessageText(Constants.DAY,"day")}
                                        </div>
                                        {
                                            CommonUtil.EQUIP_RESERVE_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE?<div className="col-sm-6 col-lg-3 mb-2" style={{display:(listingType == "Rental"?"":"none")}}>
                                                {CommonUtil.getMessageText(Constants.ENGINE_UNIT_USAGE,"Engine Unit Usage")}: <input type="text" className="d-inline-block width-100 pl-2" required
                                                                                                                                     value={engineRate} onChange={(e) => {onChangeEngineRate(e)}} /> /{CommonUtil.getMessageText(Constants.HOUR,"Hr")} </div>:""
                                        }

                                </div>
                                {/* <div className="row mt-3">
                                        <div className="col-sm-6 col-lg-3 mb-2">
                                            {CommonUtil.getMessageText(Constants.CUSTOMER_PO1,"Customer PO #")}: <input type="text" className="d-inline-block width-100 pl-2"
                                            required value={custPo} onChange={(e: any) => { setCustPo(e.currentTarget.value) }}
                                            disabled={(CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_RENTER)?true : false}/>
                                    </div>
                                    <div className="col-sm-6 col-lg-3 mb-2">
                                            {CommonUtil.getMessageText(Constants.RENTER_PO,"Renter PO #")}: <input type="text" className="d-inline-block width-100 pl-2"
                                            required value={renterPo} onChange={(e: any) => { setRenterPo(e.currentTarget.value) }}
                                            disabled={(CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER)?true : false}/>
                                        </div>
                                </div> */}

                            </section>
                        </div>
                    </div>
                    <section>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                <div className="row">
                                    <div className="col-sm-3 col-lg-3 mb-2">
                                        {CommonUtil.getMessageText(Constants.CUSTOMER_PO,"Customer PO")}: <input type="text" className="d-inline-block width-100 pl-2" onChange={(e)=>{setOwnerPO(e.target.value)}}
                                                            required value={ownerPo} />
                                    </div>
                                    <div className="col-sm-3 col-lg-3 mb-2">
                                        {CommonUtil.getMessageText(Constants.CUSTOMER_GL_COST,"Customer G/L Cost Rate")}: <input type="text"
                                                                       className="d-inline-block width-100 pl-2"
                                                                       required value={custCost} onChange={(e)=>{CommonUtil.handleNumbersDecimalInput({event:e, ref:null, setState:setCustCost})}}/>
                                    </div>
                                    <div className="col-sm-3 col-lg-3 mb-2">
                                        {"Renter PO"}: <input type="text" className="d-inline-block width-100 pl-2" onChange={(e)=>{setRenterPo(e.target.value)}}
                                                                                                                 required value={renterPo} />
                                    </div>
                                    <div className="col-sm-3 col-lg-3 mb-2">
                                        {"Owner G/L Cost Rate"}: <input type="text" className="d-inline-block width-100 pl-2" onChange={(e)=>{CommonUtil.handleNumbersDecimalInput({event:e, ref:null, setState:setOwnerCost})}}
                                                                                                                 required value={ownerCost} />
                                    </div>
                                </div>

                            </div>
                            {/* <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-right">
                                <button type="button" className="btn btn-warning waves-effect waves-light">Edit Line
                                    Item
                                </button>
                                &nbsp;
                                <button type="button" className="btn btn-warning waves-effect waves-light">Add Line
                                    Item
                                </button>
                            </div> */}
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-12" style={{paddingLeft:"0px",paddingRight:"0px"}}>
                                <div className="normal-table" style={{overflow:"hidden"}}>
                                    <div className="ag-theme-alpine gridHeight" style={{height: `calc(100vh - ${listingType === 'Rental' ? '300px' : '250px'})`, width: '100%'}} >
                                        <AGGrid id="OrgEquipSearchGrid" cols={listingType == "Rental"?invoiceColDefs:invoiceRepColDefs} getRowStyle={getRowStyle}
                                                rows={rowsData} pageSize={500} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                ref={gridRef} addNew={false} method={{onChangeStartDate,onChangeEndDate,onChangeGridInputs}}  />
                                    </div>
                                </div>
                            </div>
                            {/* CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER */}
                            {CommonUtil.isRootUser() || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER ? (
                            <div className="col-md-6 mt-3">
                                {CommonUtil.getMessageText(Constants.NOTES,"Notes")}
                                <textarea required={true} rows={7} placeholder="" value={notes} onChange={(e)=>{setNotes(e.target.value)}}></textarea>
                            </div>
                            ):null}

                            <div className={CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_RENTER ? 'col-md-12 mt-3' : "col-md-6 mt-3"}>
                                {CommonUtil.getMessageText(Constants.CUSTOMER_NOTES,"Customer Notes")}
                                <textarea required={true} rows={7} placeholder="" disabled={CommonUtil.isRootUser() ? false : CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_RENTER ? false : true}
                                 value={custNotes} onChange={(e)=>{setCustNotes(e.target.value)}}></textarea>
                            </div>
                        </div>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <div className="row fixed">
                            <div className="col-2 text-center">
                            </div>
                            <div className="col-sm-8 text-center p-0">
                                <button type="button" className="btn-sm btn-primary waves-effect" style={{display:"none"}}
                                        data-dismiss="modal" onClick={printPDF}>{CommonUtil.getMessageText(Constants.GENERATE_PDF,"Generate PDF")}
                                </button>
                                &nbsp;
                                <button type="button" className="btn-sm btn-primary waves-effect"
                                        data-dismiss="modal" onClick={onClickSave}>{CommonUtil.getMessageText(Constants.SAVE,"Save")}
                                </button>
                                &nbsp;
                                <button type="button" className={isPrextraDisable?"btn-sm  waves-effect":"btn-sm btn-primary waves-effect"}
                                        data-dismiss="modal" disabled={isPrextraDisable} onClick={onClickSavePrextra}>{CommonUtil.getMessageText(Constants.SEND_TO_PREXTRA,"Save & Send to Prextra")}
                                </button>
                                &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;
                                <button type="button" id={"btnNextProcess"} className="btn-sm btn-primary waves-effect"
                                        data-dismiss="modal" onClick={onClickNextProcess}>{CommonUtil.getMessageText(Constants.NEXT_RESERVATION_PROCESS,"Next Reservation Process")}
                                </button>
                            </div>
                            <div className="col-2 text-center">
                            </div>
                        </div>

                    </section>
                </div>
            </div>
        </>
    )
}

export default InvoiceReport;
