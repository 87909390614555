import React, { useEffect, useRef, useState } from "react";
import { CommonUtil } from "../../utils/CommonUtil";
import { Constants } from "../Languages/Constants";
import { NetworkManager } from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";
import { StatusCodeUtil } from "../../utils/StatusCodeUtil";

const WhiteLabelInvoice = ({whiteLabelLogo}:any) => {
  const [isWhiteLabel, setIsWhiteLabel] = useState(false);

  useEffect(() => {
    getWhiteLabelInvoice();
    setIsWhiteLabel(whiteLabelLogo || false);
  }, [whiteLabelLogo]);

  const getWhiteLabelInvoice = ()=>{
    console.log("sfsf asda--- ");
    
    let reqObj: any = {};
    const saas_org_id = CommonUtil.getSelectedOrgId();
    var listAPI: string = `api/organization/${saas_org_id}`;

    NetworkManager.sendJsonRequest(
      listAPI,
      "",
      "",
      "GET",
      true,
      onWhiteLabelChange,
      onLoginError
    );
  }
  const onWhiteLabelChange = (dataObj: any) => {
    if (dataObj) {
      var restData = JSON.parse(dataObj);
      if (restData) {
        setIsWhiteLabel(restData.whiteLabelLogo);
      }
    }
    
  };

  const onLoginError = (err: any) => {
    console.log(err);
  };

  const saveWhiteLabelInvoice = (flag:boolean) => {
    let reqObj: any = {};
    const saas_org_id = CommonUtil.getSelectedOrgId();
    var listAPI: string = `api/organization/updateWhiteLabelToggle/${saas_org_id}?whiteLabelLogo=${flag}`;

    NetworkManager.sendJsonRequest(
      listAPI,
      reqObj,
      "",
      "PUT",
      true,
      onSaveWhiteLabel,
      onLoginError
    );
  };

  let onSaveWhiteLabel = (dataObj: any) => {
    if (dataObj) {
      try{
      var restData = JSON.parse(dataObj);
      if (restData) {
        if (restData?.statusCode) {
          ToastManager.showToast(
            StatusCodeUtil.getCodeMessage(restData.statusCode),
            "Error"
          );
        } else {
          ToastManager.showToast(
            CommonUtil.getMessageText(
              Constants.UPDATED_SUCCESSFULLY,
              "Updated Successfully"
            ),
            "Info"
          );
        }
      }
    }catch(e){}
    }
  };

  return (
    <>
      
         <div className="col-md-12 gen-panel" style={{ paddingTop: "10px" }}>
          <div
            className="col-md-10"
            style={{ paddingLeft: "10px", paddingTop: "10px" }}
          >
            <label>
              {CommonUtil.getMessageText(
                Constants.WHITE_LABEL_INVOICE,
                "White Label Invoice"
              )}
            </label>
          </div>
          <div
            style={{
              height: "auto !important",
              border: "1px solid #dad7d7",
              backgroundColor: "white",
            }}
            className="col-12 p-2 m-0 row"
          >
            <div
              style={{ paddingTop: "15px" }}
              className="row col-md-12 col-12"
            >
              <div className="flex col-md-3 col-3">
                <input
                  type="checkbox"
                  className="checkBox"
                  disabled={CommonUtil.isRootUser() ? false : true}
                  onChange={(e) => {
                    setIsWhiteLabel(e.currentTarget.checked);
                    // saveWhiteLabelInvoice(e.currentTarget.checked);
                  }}
                  checked={isWhiteLabel ? true : false}
                />
                &nbsp;&nbsp;
                <label>
                  {CommonUtil.getMessageText(
                    Constants.ENABLE_WHITE_LABEL_INVOICE,
                    "Enable White Label Invoice"
                  )}
                </label>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="my-1  text-right pb-2 pr-2">
              <button
                type="submit"
                className="btn btn-primary waves-effect waves-light"
                disabled={CommonUtil.isRootUser() ? false : true}
                onClick={()=>saveWhiteLabelInvoice(isWhiteLabel)}
              >
                {CommonUtil.getMessageText(Constants.SAVE, "Save")}
              </button>
            </div>
          </div>
        </div> 
    </>
  );
};

export default WhiteLabelInvoice;