import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DraggableComponent from "../../Components/DraggableComponent";
import { NetworkManager } from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";
import { CommonUtil } from "../../utils/CommonUtil";
import DetectDevice from "../../utils/DetectDevice";
import { Constants } from "../Languages/Constants";
import { Events } from "../Languages/Events";
import { subscribe, unsubscribe } from "./CustomEvents";
import LoginHeader from "./LoginHeader";

const LoginPage = () => {
    const { isMobile } = DetectDevice();
    const navigate = useNavigate();
    // const history = useHistory();
    const [userName, setUserName] = useState("");
    const [fuserName, setFUserName] = useState("");
    const [password, setPassword] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [showRestPassPopup, setShowResetPassPopup] = useState(false);

    const formRef = useRef<HTMLFormElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const fEmailRef = useRef<HTMLInputElement>(null);
    const passRef = useRef<HTMLInputElement>(null);

    const [languages, setLanguages] = useState(CommonUtil.languages);
    const [selLanguage, setSelLanguage] = useState(CommonUtil.SEL_LANGUAGE_TEXT);

    const [emailText, setEmailText] = useState("");
    const [passText, setPassText] = useState("");
    const [fPassText, setFPassText] = useState("");
    const [signUpText, setSignUpText] = useState("");
    const [page, setPage] = useState(0);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    //const [loginTextText, setSignUpText] = useState("");

    useEffect(() => {
        CommonUtil.addScreensToLastAccessed("login");
        if (CommonUtil.SELECTED_LANGUAGE == CommonUtil.ENG_LANGUAGE) {
            CommonUtil.displayEnglsiMessages(() => {
                // console.log(CommonUtil.getMessageText("EMAIL"));
                showMessages();
            });
        } else if (CommonUtil.SELECTED_LANGUAGE == CommonUtil.FR_LANGUAGE) {
            CommonUtil.displayFrenchiMessages(() => {
                showMessages();
            });
        } else if (CommonUtil.SELECTED_LANGUAGE == CommonUtil.SP_LANGUAGE) {
            CommonUtil.displaySpanishiMessages(() => {
                showMessages();
            });
        }

        if (CommonUtil.SELECTED_LANGUAGE == CommonUtil.ENG_LANGUAGE) {
            CommonUtil.displayFrenchiMessages(() => {
            });
            CommonUtil.displaySpanishiMessages(() => {
            });
        } else if (CommonUtil.SELECTED_LANGUAGE == CommonUtil.FR_LANGUAGE) {
            CommonUtil.displayEnglsiMessages(() => {
            });
            CommonUtil.displaySpanishiMessages(() => {
            });
        } else if (CommonUtil.SELECTED_LANGUAGE == CommonUtil.SP_LANGUAGE) {
            CommonUtil.displayEnglsiMessages(() => {
            });
            CommonUtil.displayFrenchiMessages(() => {
            });
        }

        document.addEventListener('keypress', function (e) {
            if (e.key === 'Enter') {
                onClickLogin();
            }
        });

        subscribe(Events.LANGUAGE_CHANGE_EVENT, (data: any) => {
            // console.log(CommonUtil.SELECTED_LANGUAGE);
            showLanguageMessages();
        });

        return () => {
            unsubscribe(Events.LANGUAGE_CHANGE_EVENT, {});
        }

    }, []);

    let showLanguageMessages = () => {
        showMessages();
    }

    let onClickSignup = () => {
        CommonUtil.addScreensToLastAccessed("signup");
        navigate("/signup");
    }


    let onEmailChange = (e: any): void => {
        let uname = e.currentTarget.value;
        setUserName(uname);
        setErrorMsg("");
        CommonUtil.isEmailMandatory(emailRef);
    }

    let onFEmailChange = (e: any) => {
        let uname = e.currentTarget.value;
        setFUserName(uname);
        CommonUtil.isEmailMandatory(fEmailRef);
    }

    let onChangePass = (e: any): void => {
        setPassword(e.currentTarget.value);
        setErrorMsg("");
        CommonUtil.isMandatory(passRef);
    }

    let onClickLogin = () => {
        var isUNameMandatory = CommonUtil.isEmailMandatory(emailRef);
        var isPassMandatory = CommonUtil.isMandatory(passRef);
        if (isUNameMandatory && isPassMandatory) {
            emailRef.current?.classList.add("is-valid");
            var restObj: any = {};//"userName": "fn2l32n3@yahoo.com","password": "admin1234"};
            if (emailRef.current) {
                restObj.userName = CommonUtil.trim(emailRef.current.value);
            }
            if (passRef.current) {
                restObj.password = CommonUtil.trim(passRef.current.value);
            }

            NetworkManager.sendJsonRequest("api/auth/login", restObj, "", "POST", true, onLoginSuccess, onLoginError);
        } else {

        }
    }

    let onLoginSuccess = (data: any) => {
        //console.log(data);
        if (data) {
            var restData = CommonUtil.parseData(data);
            if (restData) {
                if (emailRef.current) {
                    localStorage.setItem("user", emailRef.current.value);
                }
                localStorage.setItem("token", restData.token);
                localStorage.setItem("refreshToken", restData.refreshToken);
                localStorage.setItem("orgId", restData.saasOrgId);
                localStorage.setItem("saasorgId", restData.saasOrgId);
                localStorage.setItem("orgName", restData.orgName);
                localStorage.setItem("root", restData.root);
                localStorage.setItem("userLogin", "false");
                localStorage.setItem("userId", restData.userId);
                localStorage.setItem("ViewTab", "false");
                localStorage.setItem("ViewDB", "true");

                CommonUtil.LOGIN_USER_ID = restData.userId;
                CommonUtil.addScreensToLastAccessed("menu");
                navigate("/menu");//,"_blank");

                let equipAllAPI: string = 'api/reservation/getReservationProcessKey?driverId=51&reservationCode=UFOSGHD';// + qParams;
                //NetworkManager.sendJsonRequest(equipAllAPI, '', '', 'GET', true, onGenerateReservationKey, onLoginError);
                //getRefreshToeken();
            }
        }

    }

    let getRefreshToeken = () => {
        let refreshTokenAPI: string = 'api/auth/refreshToken';// + qParams;
        let req: any = {};
        req.refreshToken = localStorage.getItem("refreshToken");
        NetworkManager.sendJsonRequest(refreshTokenAPI, req, '', 'POST', true, onGetRefreshToeken, onLoginError);
    }

    let onGetRefreshToeken = (dataObj: any) => {
        console.log(dataObj);
    }

    let onGenerateReservationKey = (dataObj: any) => {
        console.log(dataObj);
    }

    let onOrgDetailsSuccess = (data: any) => {
        console.log(data);
    }
    let onLoginError = (err: any) => {
        console.log(err);
    }

    let onClickReset = () => {
        let uname = fuserName.trim();
        var isUNameMandatory = CommonUtil.isEmailMandatory(fEmailRef);
        if (isUNameMandatory) {
            var restObj: any = {};//"userName": "fn2l32n3@yahoo.com","password": "admin1234"};
            restObj.userName = fuserName;
            //restObj.password = password;
            var qParam = "email=" + fuserName;
            NetworkManager.sendJsonRequest("api/session/createResetPassword", "", qParam, "POST", true, onResetSuccess, onLoginError);
        } else {

        }
    }

    let onResetSuccess = (data: any) => {
        //console.log(data);
        if (data) {
            var restData = JSON.parse(data);
            if (restData) {
                if (restData.statusCode === 403) {
                    ToastManager.showToast(restData.errorMessage, "Error");
                    return;
                    // setErrorMsg(StatusCodeUtil.getCodeMessage(restData.statusCode));
                } else {
                    /* console.log(restData);
                     localStorage.setItem("token",restData.token);
                     localStorage.setItem("refreshToken",restData.refreshToken);
                     navigate("/menu");*/
                }
                ToastManager.showToast("Email sent successfully", "Info");
                onCloseResetPassPopup();
                setTimeout(() => {
                    navigate('/regreset');
                }, 500);
                }
        } else {
            ToastManager.showToast("Email sent successfully", "Info");
            onCloseResetPassPopup();
            setTimeout(() => {
                navigate('/regreset');
            }, 500);
        }
    }
    let gotoLoginPage = () => {
        navigate('/regsignup')
    }

    let onCloseResetPassPopup = () => {
        setShowResetPassPopup(false);
    }

    let onClickForgetPass = () => {
        setFUserName("");
        setShowResetPassPopup(true);
        //navigate("/forgetPassword");
    }

    let onClickLanOption = (item: any) => {
        //console.log(item);
        CommonUtil.SELECTED_LANGUAGE = item.Key;
        if (item.Key == CommonUtil.ENG_LANGUAGE) {
            CommonUtil.SEL_LANGUAGE_TEXT = CommonUtil.ENG_LANGUAGE_TEXT;
            setSelLanguage(CommonUtil.ENG_LANGUAGE_TEXT);
        } else if (item.Key == CommonUtil.FR_LANGUAGE) {
            CommonUtil.SEL_LANGUAGE_TEXT = CommonUtil.FR_LANGUAGE_TEXT;
            setSelLanguage(CommonUtil.FR_LANGUAGE_TEXT);
        } else if (item.Key == CommonUtil.SP_LANGUAGE) {
            CommonUtil.SEL_LANGUAGE_TEXT = CommonUtil.SPANISH_LANGUAGE_TEXT;
            setSelLanguage(CommonUtil.SPANISH_LANGUAGE_TEXT);
        }

        showMessages();
    }

    let showMessages = () => {
        setPage(Math.random() * 100000);
        //setEmailText(CommonUtil.getMessageText(Constants.EMAIL));
        //setPassText(CommonUtil.getMessageText(Constants.PASS));
        //setFPassText(CommonUtil.getMessageText(Constants.F_PASS));
    }

    return (
        <React.Fragment>
            <Modal show={showRestPassPopup} dialogAs={DraggableComponent}>
                <div className="modal-content" style={{ "width": "500px", "height": "350px" }}>
                    <div className="modal-header modalPopupHeader">
                        <h6 className="modal-title darkgrey-text fw-600">{CommonUtil.getMessageText(Constants.FORGET_PASS, "Forgot Password")}</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ marginLeft: "0px" }}
                            onClick={onCloseResetPassPopup}>
                            <span aria-hidden="true">&times;</span>

                        </button>
                    </div>
                    <div className="modal-body" style={{ paddingTop: "10px" }}>
                        <div className="clsModalBodyPermissions">
                            <span className="darkgrey-text fs-7">{CommonUtil.getMessageText(Constants.ENTER_EMAIL, "Enter your email and we will send you a link to get back into your account")}</span>
                            <div className="mb-2  email-margin-bottom">
                                <label>{CommonUtil.getMessageText(Constants.EMAIL_ID, "Email ID")} </label>
                                <input ref={fEmailRef} type="text"
                                    className="form-control clsStandardText clsFormInputControl"
                                    placeholder={CommonUtil.getMessageText(Constants.PLACE_ENTER_EMAIL, "Enter Email")} value={fuserName}
                                    style={{ width: "100%" }}
                                    onChange={(e) => {
                                        onFEmailChange(e)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer clsModalPermissionsFootr">
                        <button type="button" className="btn btn-outline-dark" data-bs-dismiss="modal"
                            onClick={onCloseResetPassPopup}>{CommonUtil.getMessageText(Constants.CANCEL, "Cancel")}
                        </button>
                        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={onClickReset}>
                            {CommonUtil.getMessageText(Constants.RESET_PASSWORD, "Reset Password")}
                        </button>
                    </div>
                </div>

            </Modal>
            <LoginHeader isLogin={"false"} isLanguage={"false"} />
            <div className={`container-login`} style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                <div className="row vh-93">
                    {
                        isMobile ? (
                            <div className="col-sm-12 col-md-8 p-0">
                                <div id="mmyCarousel" className="carousel slide" data-bs-interval='3000' data-bs-pause="false" data-bs-ride="mcarousel">
                                    <ol className="carousel-indicators">
                                        <li data-bs-target="#mmyCarousel" data-bs-slide-to="0" className="active"></li>
                                        <li data-bs-target="#mmyCarousel" data-bs-slide-to="1"></li>
                                        <li data-bs-target="#mmyCarousel" data-bs-slide-to="2"></li>
                                    </ol>

                                    <div className="carousel-inner" role="listbox" style={{ width: '100%', height: 230, overflow: 'hidden' }} >

                                        <div className="carousel-item active" style={{ width: '100%', height: '100%' }} >
                                            <img src="../../images/1.jpg" style={{ width: '100%', backgroundSize: 'cover', height: 'inherit' }} alt="..." />
                                            <div className="carousel-caption">
                                                <h5>Improve Asset Utilization</h5>
                                                <p>Put Your Trailer on vHub</p>
                                            </div>
                                        </div>

                                        <div className="carousel-item" style={{ width: '100%', height: '100%' }}>
                                            <img src="../../images/2.jpg" style={{ width: '100%', backgroundSize: 'cover', height: 'inherit' }} alt='...' />
                                            <div className="carousel-caption">
                                                <h5>Find Capacity, Improve Marings</h5>
                                                <p>Find Trailers on vHub.</p>
                                            </div>
                                        </div>

                                        <div className="carousel-item login-banner-bg" style={{ width: '100%', height: '100%' }}>
                                            <img src="../../images/banner1.png" style={{ width: '50%', backgroundSize: 'cover', height: 'inherit', left: "25%", position: "inherit" }} alt="..." />
                                            <div className="carousel-caption">
                                                <h5>Trailers at your Fingertips</h5>
                                                <p>Increase your Cost Per Mile as an Owner-Operator</p>
                                            </div>
                                        </div>


                                    </div>

                                    <a className="left carousel-control" href="#mmyCarousel" role="button" data-bs-slide="prev">
                                        <span className="glyphicon glyphicon-chevron-left" aria-hidden="false"></span>
                                        <span className="sr-only">Previous</span>
                                    </a>
                                    <a className="right carousel-control" href="#mmyCarousel" role="button" data-bs-slide="next">
                                        <span className="glyphicon glyphicon-chevron-right" aria-hidden="false"></span>
                                        <span className="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                        ) : (
                            <div className="col-sm-12 col-md-8 p-0">
                                <div id="myCarousel" className="carousel slide" data-bs-interval='3000' data-bs-pause="false" data-bs-ride="carousel">
                                    <ol className="carousel-indicators">
                                        <li data-bs-target="#myCarousel" data-bs-slide-to="0" className="active"></li>
                                        <li data-bs-target="#myCarousel" data-bs-slide-to="1"></li>
                                        <li data-bs-target="#myCarousel" data-bs-slide-to="2"></li>
                                    </ol>

                                    <div className="carousel-inner" role="listbox">

                                        <div className="carousel-item login-banner-bg1 active">
                                            <img src="../../images/1.jpg" alt="..." className="d-block position-absolute top-50 start-50 translate-middle"
                                                style={{ width: "100%" }} />
                                            <div className="carousel-caption">
                                                <h5>Improve Asset Utilization</h5>
                                                <p>Put Your Trailer on vHub</p>
                                            </div>
                                        </div>

                                        <div className="carousel-item login-banner-bg1">
                                            <img src="../../images/2.jpg" className="d-block position-absolute top-50 start-50 translate-middle"
                                                style={{ width: "100%" }} />
                                            <div className="carousel-caption">
                                                <h5>Find Capacity, Improve Marings</h5>
                                                <p>Find Trailers on vHub.</p>
                                            </div>
                                        </div>

                                        <div className="carousel-item login-banner-bg">
                                            <img src="../../images/banner1.png" className="d-block position-absolute top-50 start-50 translate-middle"
                                                alt="..." />
                                            <div className="carousel-caption">
                                                <h5>Trailers at your Fingertips</h5>
                                                <p>Increase your Cost Per Mile as an Owner-Operator</p>
                                            </div>
                                        </div>


                                    </div>

                                    <a className="left carousel-control" href="#myCarousel" role="button" data-bs-slide="prev">
                                        <span className="glyphicon glyphicon-chevron-left" aria-hidden="false"></span>
                                        <span className="sr-only">Previous</span>
                                    </a>
                                    <a className="right carousel-control" href="#myCarousel" role="button" data-bs-slide="next">
                                        <span className="glyphicon glyphicon-chevron-right" aria-hidden="false"></span>
                                        <span className="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                        )
                    }


                    <div className="col-md-4">
                        <div className="row ms-4 mt-3 me-4 border-bottom d-none" style={{ "height": "37.5px" }}>
                            <div className="col-md-4 ps-0"><a href="#" target="_blank">
                                <div className="logo"></div>
                            </a></div>
                            <div className="col-md-8 pe-0">
                                <p className="float-end grey-text fs-6"><a
                                    className="grey-text text-decoration-none">{CommonUtil.getMessageText(Constants.SEARCH, "Search")}</a> <a href="#"
                                        className="grey-text text-decoration-none ps-3">FR</a>
                                </p>
                            </div>
                        </div>
                        <div className="mx-auto">
                            <div className="row ms-4 mt-3 me-4 login-vertical-center">
                                <h4 className="darkgrey-text">{CommonUtil.getMessageText(Constants.WELCOME, "Welcome!")}</h4>
                                <p className={`darkgrey-text ${isMobile ? 'mb-0 pb-0' : 'pb-4'}`} style={{ fontSize: "14px" }}>
                                    {CommonUtil.getMessageText(Constants.CREDENTIALS, "Please enter your credentials to login")}</p>
                                <form ref={formRef} className={`pb-4 border-bottom needs-validation requires-validation`}
                                    noValidate>
                                    <div className={`text-center ${isMobile ? '' : 'mb-3'}`}>
                                        <label className="form-label loginError fs-6">{errorMsg}</label>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">{CommonUtil.getMessageText(Constants.EMAIL, "Email")}</label>
                                        <input ref={emailRef} type="email"
                                            className="form-control login-input clsFormInputControl" id="inputEmail"
                                            required={true} placeholder="Email"
                                            onFocus={() => { setShowPassword(false) }}
                                            value={userName} onChange={(e) => {
                                                onEmailChange(e)
                                            }} />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">{CommonUtil.getMessageText(Constants.PASS, "Password")}</label>
                                        <div className="input-group" >
                                            <input ref={passRef} type={showPassword ? 'text' : 'password'}
                                                className="form-control login-input clsFormInputControl "
                                                id="inputPassword" placeholder="Password"
                                                value={password} required={true} onChange={(e) => {
                                                    onChangePass(e)
                                                }} />
                                            <span className="input-group-text" onClick={() => { setShowPassword(!showPassword) }} style={{ cursor: 'pointer' }} >{showPassword ? <i className="fa-solid fa-eye-slash" style={{ color: '#3B71CA' }} aria-hidden="true" ></i> : <i className="fa fa-eye" style={{ color: '#3B71CA' }} aria-hidden="true"></i>}</span>
                                        </div>
                                    </div>
                                    <div className="mb-3 float-end">
                                        <a className=" darkgrey-text fs-6 text-decoration-none form-control-font"
                                            onClick={onClickForgetPass}>{CommonUtil.getMessageText(Constants.F_PASS, "Forgot Password?")}</a>
                                    </div>
                                    <button type="button" className="btn login-btn w-100 login-btn-font"
                                        onClick={onClickLogin} style={{ height: "35px" }}>
                                        {/* <i className="fa fa-refresh fa-spin fa-2x fa-fw color-red spinner-color"></i> &nbsp;*/}
                                        {CommonUtil.getMessageText(Constants.LOGIN, "Login")}
                                    </button>
                                </form>
                                <p className="pt-2 text-center darkgrey-text account fs-6 form-control-font">{CommonUtil.getMessageText(Constants.DONT_ACCOUNT, "Don't have an account? ")} <a className="signup-text text-decoration-underline"
                                    onClick={onClickSignup}>{CommonUtil.getMessageText(Constants.SIGN_UP, "Sign Up")} </a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default LoginPage;
