import React, { useEffect, useRef, useState } from "react";
import DetectDevice from "../../utils/DetectDevice";
import { CommonUtil } from "../../utils/CommonUtil";
import { Constants } from "../Languages/Constants";
import MGrid from "../../Components/MGrid";
import AGGrid from "../../Components/AGGrid";
import { NetworkManager } from "../../Components/NetworkManager";
import SingleSelectComp from "../../Components/SingleSelectComp";
import { Events } from "../Languages/Events";
import ToastManager from "../../Components/ToastManager";
let selectedOrgId = "";
let gridData:any =[];
const ConsolidatedStatement = () => {
  const { isMobile } = DetectDevice();

  
  const [equipData, setEquipData] = useState<any>([]);
  const [orgData, setOrgData] = useState<any>([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selCompanyObj, setSelCompanyObj] = useState<any>({});
  const gridRef = useRef<any>(null);

  

  const [eqpColumnDefs] = useState([
    {
      field: "batchNumber",
      headerName: "Batch Number",
      resizable: true,
      width: 250,
      sortable: true,
      filter: "agTextColumnFilter",
      // pinned: "left",
    },
    {
      field: "counts",
      headerName: "Total Invoice",
      resizable: true,
      width: 250,
      sortable: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params: any) => (
        <span>
          {params.data.batchId ? (
            !params.data.isOpen ? (<button className="btn btn-info" style={{padding:"0.2rem"}} onClick={(e: any) => {
              processHierarchyData(params.data.batchId, true,params.api);
            }}><i className="fa-solid fa-plus" ></i></button>) : (
              <button className="btn btn-info" style={{padding:"0.2rem"}} onClick={(e: any) => {
                processHierarchyData(params.data.batchId, false,params.api);
              }}><i className="fa-solid fa-minus"></i>              
            </button>)
          ) : (
            ""
          )}
          {params.data.counts}
        </span>
      ),
      // pinned: "left",
    },{
      field: "invoiceCodeOrStatementCode",
      headerName: "Statement Code",
      resizable: true,
      width: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
      // pinned: "right",
    },
    {
      field: "reservtaionCode",
      headerName: "Reservtaion",
      resizable: true,
      width: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
      // pinned: "right",
    },
    {
      field: "lastUpdateDate",
      headerName: "Last Update Date",
      resizable: true,
      width: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
      // pinned: "right",
    },
    {
      field: "batchStatus",
      headerName: "Batch Status",
      resizable: true,
      width: 200,
      sortable: true,
      // cellRenderer:(params:any)=>{
      //   const onClickItem = (e:any)=>{ 
      //       downloadPDF();
      //   }
      //   return (<>
      //     {params?.value && <div className={"cellRenderHeader"}>
      //       <a><span onClick={onClickItem} className={"cellRender"}>{"Retry"}</span></a>
      //   </div>}
      //   </>
      //   )
      // },
      // pinned: "right",
    },
    {
      field: "remainingBalance",
      headerName: "Remaining Balance",
      resizable: true,
      width: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
      // pinned: "right",
    },
    {
      field: "paidBalance",
      headerName: "Paid Balance",
      resizable: true,
      width: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
      // pinned: "right",
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      resizable: true,
      width: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
      // pinned: "right",
    },    
    {
      field: "download",
      headerName: "download",
      resizable: true,
      width: 200,
      sortable: true,
      cellRenderer:(params:any)=>{
        const onClickItem = (e:any)=>{ 
            downloadPDF(params.data.batchId, params.data.batchNumber);
        }
        return (
          <>
            {params?.data?.batchNumber && <div className={"cellRenderHeader"}>
              <a ><span onClick={onClickItem} className={"cellRender"} style={{fontSize:"20px"}}><i className="fa-solid fa-download"></i></span></a>
            </div>}
        </>
        )
      },
      // pinned: "right",
    },  
    {
      field: "sendEmail",
      headerName: "Send Email",
      resizable: true,
      width: 200,
      sortable: true,
      cellRenderer: (params: any) => {
        const onSucessEmailSend = (e:any)=>{
          ToastManager.showToast("Email Sent Successfully", "Success");
        }
        const onError = (e:any)=>{
          ToastManager.showToast("Error in Sending Email", "Error");
        }
        const onClickItem = (e: any) => {
          NetworkManager.sendJsonRequest(
            `api/download/consolidatedStatementsEmail?batchId=${params.data.batchId}&saasOrgId=${params.data.saasOrgId}`,
            "",
            "",
            "GET",
            true,
            onSucessEmailSend,
            onError
          );
        }
        return (
          <>
            {params?.data?.batchNumber && (
              <div className={"cellRenderHeader"}>
                <a>
                  <span
                    onClick={onClickItem}
                    className={"cellRender"}
                    style={{ fontSize: "20px" }}
                  >
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                  </span>
                </a>
              </div>
            )}
          </>
        );
      },
    },
  ]);

  

  const downloadPDF = async (batchId:any, batchNumber: any) => {
    if(CommonUtil.getSelectedOrgId()){
        var myHeaders = new Headers()
        myHeaders.append('Content-Type', 'application/json')
        if (localStorage.getItem('token')) {
            var token = 'Bearer ' + localStorage.getItem('token')
            myHeaders.append('Authorization', token);
        }
        let qParam = selectedOrgId || CommonUtil.getSelectedOrgId();
        await fetch(NetworkManager.serverPath + `api/download/consolidatedStatements?saasOrgId=${qParam}&batchId=${batchId}`, {method:'GET', headers:myHeaders })
            .then((response:any) => {
                response.blob().then((blob:any) => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `${batchNumber}.pdf`;
                    a.click();
                });

            }).catch((error)=>{})
    }
}

  const getAllStatementsForOrg = () => {
    console.log(selectedOrgId);
    gridData = [];
    let qParam = selectedOrgId || CommonUtil.getSelectedOrgId();
    let usrUrl = `api/reservation/findAllBatches/statements?saasOrgId=${qParam}`;
    // let usrUrl = `api/reservation/getAllStatementsForOrg?saasOrgId=${qParam}`;
    NetworkManager.sendJsonRequest(
      usrUrl,
      "",
      "",
      "GET",
      true,
      onSuccessgetAllStatementsFororg,
      onFailgetAllStatementsFororg
    );
  };

  const onSuccessgetAllStatementsFororg = (dataObj: any) => {
    if (dataObj) {
      var restData = CommonUtil.parseData(dataObj);
      if (restData) {
        if (Array.isArray(restData)) {
          console.log(restData);
          setEquipData(restData);
          let tot = 0;
          restData?.map(({totalAmount}:any)=>tot+=Number(totalAmount));
          setTotalAmount(tot);
          gridData = restData;
        }
      }
    }
  };

  const onFailgetAllStatementsFororg = () => {};

  useEffect(() => {
    if (CommonUtil.isRootUser()) {
      CommonUtil.getAllOrgs((data: any) => {
        let arr:any = [];// [{ orgId: null, orgName: "All" }];
        setOrgData([...arr, ...data]);
        data?.map((ele:any)=>{
          if(CommonUtil.getSelectedOrgId() == ele.orgId){
            setSelCompanyObj(ele);
            selectedOrgId = ele.orgId;
            console.log(CommonUtil.getSelectedOrgId(),ele);
          }
          
        })       
      });
    }else{
      let arr = [{ orgId: CommonUtil.getSelectedOrgId(), orgName: CommonUtil.getSelectedOrgName()}];
        setOrgData([...arr]);
        setSelCompanyObj({ orgId: CommonUtil.getSelectedOrgId(), orgName: CommonUtil.getSelectedOrgName()});     
        selectedOrgId = CommonUtil.getSelectedOrgId();      
    }
    
    getAllStatementsForOrg();
    document.addEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
    return () => {
      document.removeEventListener(
        Events.SWITCH_CUSTOMER_EVENT,
        onSwitchCustomer
      );
      selectedOrgId = CommonUtil.getSelectedOrgId();
    };
  }, []);

  const onSwitchCustomer = (e: any) => {
    if (CommonUtil.isRootUser()) {
      Events.dispatchBreadCrumbEvent();
      CommonUtil.getAllOrgs((data: any) => {
        let arr:any = [];// [{ orgId: null, orgName: "All" }];
        setOrgData([...arr, ...data]);
        if(data.orgId == CommonUtil.getSelectedOrgId())
          setSelCompanyObj(data);
      });
      selectedOrgId = CommonUtil.getSelectedOrgId();
    }
    
      Events.dispatchBreadCrumbEvent();
    // getAllStatementsForOrg();
  };
  const onCompanyChange = (dataObj: any) => {
    setSelCompanyObj(dataObj);
    selectedOrgId = dataObj.orgId;
  };

  const getBatchInvoices = async (batchId:any)=>{
    return new Promise((resolve, reject) =>{
      const onSucess = (dataObj:any)=>{
        if (dataObj) {
          var restData = CommonUtil.parseData(dataObj);
          if (restData) {
            resolve(restData); 
          }
        }
      }

      const onError = (dataObj:any)=>{
        reject(new Error('Network error'));
      }
      NetworkManager.sendJsonRequest(
        `api/reservation/getBatchStatements/${batchId}`,
        "",
        "",
        "GET",
        true,
        onSucess,
        onError
      );
    });
  }

  const processHierarchyData= async (batchId:any,openStatus:boolean = false,gridAPI:any=[])=>{

    let data:any = [];
    let batchData:any = [];
    try{
      if(openStatus){
        batchData = await getBatchInvoices(batchId);
        batchData = batchData?.map((ele:any)=>{
          let obj:any = {...ele};
          obj.totalAmount = obj.amount;
          obj.batchStatus = obj.paymentStatus;
          obj.paidBalance = obj.balancePaid;
          obj.lastUpdateDate = obj.issueDate;
          obj.remainingBalance = obj.remaingBalance;
          return obj;
        })
      }
    }catch(e){
      batchData = [];
    }

    gridData = gridData?.map((ele:any)=>{
      let obj:any = {...ele};
      if(obj.batchId === batchId){
        obj.isOpen = openStatus;
        obj.children = openStatus ? [...batchData] : [];
      }
      return obj;
    })


    gridData?.map((ele:any)=>{
      let obj:any = {...ele};
      if(obj?.children?.length > 0){
        const children:any = [...obj?.children];
        obj.children = [];
        data.push(obj);
        children.map((child:any)=>{
          let obj1:any = {...child};
          obj1.isChildOf = obj.batchId;
          obj1.batchId = "";
          data.push(obj1);
        })
      }else{
        data.push(obj);
      }
      
    })


    console.log(gridData,data);
    
    setEquipData([...data]);
  }
  

  return (
    <>
      <div
        className="container-fluid container-fluid-padding"
        style={{ paddingTop: "15px" }}
      >
        <div className="row ">
            {/* <div className="col-12"> */}
                <div
                    className="col-md-4 col-3"
                    style={{
                    zIndex: 999,
                    // display: CommonUtil.isRootUser() ? "" : "none",
                    }}
                >
                    <span className="black-text css-16ihq8s">
                    {CommonUtil.getMessageText(Constants.COMPANY, "Company")}
                    </span>
                    <SingleSelectComp
                    dataSource={orgData}
                    selectedValue={selCompanyObj}
                    width={"100%"}
                    handleChange={onCompanyChange}
                    value={"orgId"}
                    label={"orgName"}
                    disabled={CommonUtil.isRootUser() ? false:true}
                    />
                </div>
                <div className="col-md-4 col-3 pt-4">
                        <button type="button" className="btn btn-warning cus-btn btn-sm button-height"
                            // onClick={filterReservations}
                                onClick={getAllStatementsForOrg}>
                            {CommonUtil.getMessageText(Constants.SEARCH,"Search")}
                        </button>
                        <span
                          style={{
                            fontSize: "0.7rem",
                            margin: "10px",
                            textAlign: "right",
                            position: "absolute",
                            right: "5%",
                          }}
                        >
                          Report data is generated for last cycle
                        </span>
                </div>
          {/* </div> */}
          
        </div>

        <div className="row">
          <div className={`col-12 ${isMobile ? "" : "pt-3"}`}>
            {isMobile ? (
              <MGrid
                id="OrgConGrid"
                cols={eqpColumnDefs}
                rows={equipData}
                pageSize={10}
                headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
                rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                ref={gridRef}
                addNew={false}
              />
            ) : (
              <div
                className="ag-theme-alpine gridHeight"
                style={{ height: "calc(100vh - 250px)", width: "100%" }}
              >
                <AGGrid
                  id="OrgConGrid"
                  cols={eqpColumnDefs}
                  rows={equipData}
                  pageSize={500}
                  headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
                  rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                  ref={gridRef}
                  addNew={false}
                />
              </div>
            )}
          </div>          
        </div>
        <div
              style={{
                fontSize: "1rem",
                textAlign: "right",
                margin:"20px"
              }}
            >
              {`Amount to be Paid: $ ${Number(totalAmount).toFixed(2)}`}
            </div>
      </div>
    </>
  );
};

export default ConsolidatedStatement;