import React, { useEffect, useState } from "react";
import { CommonUtil } from "../../utils/CommonUtil";
import { useNavigate } from "react-router";
import { NetworkManager } from "../../Components/NetworkManager";
import {Events} from "../Languages/Events";

const IncompleteBanner = () => {
  const navigate = useNavigate();
  const [isCompleted, setIsCompleted] = useState(false);
const [completedStatus, setCompletedStatus] = useState<any>({});
  const onComplete = ()=>{
    setIsCompleted(true);
  }
  const onSwitchCustomer = (event: any)=>{
    console.log(event,"on switch");
    try{
      getOrgProfileStatus();
    }catch(e){}
  }

  useEffect(() => {
    getOrgProfileStatus();
    CommonUtil.registerProfileStatusCallback(onComplete);
    document.addEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
    return ()=>{
      document.removeEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
    }
  }, []);

 

  let onLoginError = (err: any) => {
    console.log(err);
  };

  const getOrgProfileStatus = () => {
    NetworkManager.sendJsonRequest(
      "api/organization/getOrgProfileStatus?saasOrgId=" +
        CommonUtil.getSaasOrgId(),
      "",
      "",
      "GET",
      true,
      onGetIsCompleted,
      onLoginError
    );
  };

  const onGetIsCompleted = (dataObj: any) => {
    if (dataObj) {
      var restData = JSON.parse(dataObj);
      console.log("restData", restData);
      if (restData) {
        setCompletedStatus(restData);
        setIsCompleted(restData?.isCompleted);
      }
    }
  };

  const showBanner:any = completedStatus["customerDetails"] ||
    completedStatus["financeInformation"] ||
    completedStatus["insuranceDetails"] ||
    completedStatus["bankDetails"] ||
    completedStatus["creditCardDetails"];
    
  
  const shouldShowIncompleteBar = !CommonUtil.isRootUser() && !isCompleted && (showBanner );
  return (
    <div>
      {shouldShowIncompleteBar && (
        <div className="topbar-incompleteBar text-center">
          Your organization profile is incomplete, please{" "}
          <a
            onClick={(e) => {
              navigate("/menu/custProfiles");
            }}
            style={{textDecoration: "underline"}}
          >
            click here
          </a>{" "}
          to complete the profile configuration.
        </div>
      )}
    </div>
  );
};

export default IncompleteBanner;
