import React, { useEffect, useRef, useState } from "react";
import { CommonUtil } from "../../utils/CommonUtil";
import { Constants } from "../Languages/Constants";
import AGGrid from "../../Components/AGGrid";
import { NetworkManager } from "../../Components/NetworkManager";

const MonthlyInvoices = ({ reservationId,showfor }: any) => {
  const gridRef = useRef<any>(null);

  const [invoiceColumnDefs, setInvoiceColumnDefs] = useState<any>([]);
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const [invoiceHeaderData, setInvoiceHeaderData] = useState<any>({});

  const onLoginError = (err: any) => {
    console.log(err);
  };

  const getMonthlyInvoices = (reservationId: any) => {
    if (reservationId) {
      let polacyAPI: string = `api/reservation/getMonthlyInvoices?reservationId=${reservationId}`;
      if(showfor === "statement")
        polacyAPI = `api/reservation/getMonthlyStatements?reservationId=${reservationId}`;
      NetworkManager.sendJsonRequest(
        polacyAPI,
        "",
        "",
        "GET",
        true,
        onSuccessMonthlyInvoices,
        onLoginError
      );
    }
  };

  const onSuccessMonthlyInvoices = (response: any) => {
    if (response) {
      var restData = CommonUtil.parseData(response);
      if (restData) {
        console.log(restData);
        setInvoiceColumnDefs([
          {
            field: "billingDate",
            headerName: "Billing Date",
            resizable: true,
            width: 300,
            sortable: true,
            filter: "agNumberColumnFilter",
          },
          {
            field: "billStartDate",
            headerName: "Billing Start Date",
            resizable: true,
            width: 300,
            sortable: true,
            filter: "agNumberColumnFilter",
          },
          {
            field: "billEndDate",
            headerName: "Billing End Date",
            resizable: true,
            width: 300,
            sortable: true,
            filter: "agNumberColumnFilter",
          },
          {
            field: "invoiceCode",
            headerName: "Invoice Code",
            resizable: true,
            width: 300,
            sortable: true,
            filter: "agNumberColumnFilter",
            cellRenderer:(params:any)=>{
              const onClickItem = (e:any)=>{ 
                  downloadPDF(params.data.invoiceId,params.data.invoiceCode);
              }
              return (
                <div className={"cellRenderHeader"}>
                  <a ><span onClick={onClickItem} className={"cellRender"} >{params.data.invoiceCode}</span></a>
              </div>
              )
            },
          },
          {
            field: "lastOdometerReading",
            headerName: "Last Odometer Reading",
            resizable: true,
            width: 300,
            sortable: true,
            filter: "agNumberColumnFilter",
          },
          {
            field: "lastEngineHours",
            headerName: "Last Engine Hours",
            resizable: true,
            width: 300,
            sortable: true,
            filter: "agNumberColumnFilter",
          },
          {
            field: "subTotalAmount",
            headerName: "Sub Total Amount",
            resizable: true,
            width: 300,
            sortable: true,
            filter: "agNumberColumnFilter",
          },
          {
            field: "totalTaxes",
            headerName: "Total Taxes",
            resizable: true,
            width: 300,
            sortable: true,
            filter: "agNumberColumnFilter",
          },
          {
            field: "totalAmount",
            headerName: "Total Amount",
            resizable: true,
            width: 300,
            sortable: true,
            filter: "agNumberColumnFilter",
          },
        ]);
        setInvoiceData(restData?.invoices || []);
        setInvoiceHeaderData({
          lastBilledAmount: restData.lastBilledAmount,
          lastBillingDate: restData.lastBillingDate,
          nextBillingDate: restData.nextBillingDate,
        });
      }
    }
  };

  useEffect(() => {
    getMonthlyInvoices(reservationId);
  }, []);

  const downloadPDF = async (batchId:any,name:string) => {
    if(CommonUtil.getSelectedOrgId()){
        var myHeaders = new Headers()
        myHeaders.append('Content-Type', 'application/json')
        if (localStorage.getItem('token')) {
            var token = 'Bearer ' + localStorage.getItem('token')
            myHeaders.append('Authorization', token);
        }
        
        let qParam = CommonUtil.getSelectedOrgId();
        let url:string = `api/download/vHubInvoice?invoiceId=${batchId}`;
        if(showfor === "statement")
          url = `api/download/vHubStatement?statementId=${batchId}`;
        await fetch(NetworkManager.serverPath + url, {method:'GET', headers:myHeaders })
            .then((response:any) => {
                response.blob().then((blob:any) => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = showfor === "statement" ? `${name}.pdf`:`${name}.pdf`;
                    a.click();
                });

            }).catch((error)=>{})
    }
}

  return (
    <>
      <section>
        <div className="row">
          <div className="col-sm-12">
            <h6>
              {showfor !=="statement" ? CommonUtil.getMessageText(
                Constants.MONTHLY_INVOICES,
                "Monthly Invoices"
              ):CommonUtil.getMessageText(
                Constants.SOI,
                "Statement of Income"
              )}
            </h6>
            <p>
              {showfor !=="statement" ? CommonUtil.getMessageText(
                Constants.ALL_YOUR_INVOICES,
                "All your invoices will be added in this section"
              ):
              CommonUtil.getMessageText(
                Constants.ALL_YOUR_INVOICES,
                "All your statement will be added in this section"
              )}
            </p>
          </div>
          <div className="col-sm-12">
            {showfor !=="statement" ? 
              <div className="row mb-2">
                <div className="col-4">
                  <span>{`last Billed Amount: ${invoiceHeaderData.lastBilledAmount|| ''}`}</span>
                </div>
                <div className="col-4">
                  <span>{`last Billing Date: ${invoiceHeaderData.lastBillingDate|| ''}`}</span>
                </div>
                <div className="col-4">
                  <span>{`Next Billing Date: ${invoiceHeaderData.nextBillingDate|| ''}`}</span>
                </div>
              </div>:
              <div className="row mb-2">
              <div className="col-4">
                <span>{`last Statement Amount: ${invoiceHeaderData.lastBilledAmount || ''}`}</span>
              </div>
              <div className="col-4">
                <span>{`last Statement Date: ${invoiceHeaderData.lastBillingDate|| ''}`}</span>
              </div>
              <div className="col-4">
                <span>{`Next Statement Date: ${invoiceHeaderData.nextBillingDate|| ''}`}</span>
              </div>
            </div>
            }
            <div className="row">
              <div
                className="ag-theme-alpine gridHeight"
                style={{
                  height: 300,
                  width: "100%",
                  paddingLeft: "8px",
                  paddingRight: "5px",
                }}
              >
                <AGGrid
                  id="OrgInvoiceGrid"
                  cols={invoiceColumnDefs}
                  rows={invoiceData}
                  pageSize={500}
                  headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
                  rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                  ref={gridRef}
                  addNew={false}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MonthlyInvoices;
