import React, { useState, useRef, useEffect } from "react";
import { Table } from "react-bootstrap";
import NewEntryCTPATValues from "./NewEntryCTPATValues";
import { useReleaseInspectorContext } from "../../../Components/ReleaseContext";
import Form from "react-bootstrap/Form";
import BusyLoader from "../../../Components/BusyLoader";
import { NetworkManager } from "../../../Components/NetworkManager";
import { StatusCodeUtil } from "../../../utils/StatusCodeUtil";
import ToastManager from "../../../Components/ToastManager";
import { CommonUtil } from "../../../utils/CommonUtil";
import $ from "jquery";
import AttachmentsViewer from "./AttachmentsViewer";

const NewEntryCTPATInsp = ({ data }: any) => {
  let fileType: string = "";
  const [loadStatus, setLoadStatus] = useState(false);
  const { state, dispatch } = useReleaseInspectorContext();
  let qPath = CommonUtil.getInterchangeDocumentId();
  const fileref2 = useRef<HTMLInputElement>(null);
  const ctpatInspection: any = state?.masterData?.ctpatInspection?.task
    ? [...state?.masterData?.ctpatInspection?.task]
    : [];

  const submitHandler = (event: any) => {
    event.preventDefault();
    //if (state?.loadStatus) {
      //   if (
      //     state?.ctpatInspection?.sealImages?.images?.length &&
      //     state?.ctpatInspection?.sealImages?.description
      //   ) {
      //     dispatch({
      //       type: "ctpatInspection",
      //       payload: {
      //         isCompleted: true,
      //       },
      //     });
      //   } else {
      //     ToastManager.showToast(
      //       "Please upload Seal Images and add description",
      //       "Error"
      //     );
      //   }
      // } else {
      //   dispatch({
      //     type: "ctpatInspection",
      //     payload: {
      //       isCompleted: true,
      //     },
      //   });
      // }
      // Removed required
      dispatch({
        type: "ctpatInspection",
        payload: {
          isCompleted: true,
        },
      });
    //}
  };

  const onAddPictures = (cId: string, fType: string) => {
    fileType = fType;
    if (fileref2.current) {
      fileref2.current.value = "";
      // fileref.current.onclick();
      $("#inputGroupFile2").click();
    }
  };

  const getLoadStatus = () => {
    var qparam = CommonUtil.getInterchangeDocumentId();
    var orgUrl = `api/webInspectionDetails/multiRelease/getGeneralEventDetails?eventId=${state?.eventId}`;
    NetworkManager.sendJsonRequest(
      orgUrl,
      "",
      "",
      "GET",
      true,
      (dataObj: any) => {
        if (dataObj) {
          var restData = JSON.parse(dataObj);
          setLoadStatus(
            String(restData?.loadStatus).toLowerCase() === "loaded"
              ? true
              : false
          );
        }
      },
      onLoginError
    );
  };

  const onLoginError = (err: any) => {
    console.log("Something went wrong");
  };

  let onSelectFiles = (e: any) => {
    if (e && e.target && e.target.files[0]) {
      var file = e.target.files[0];
      let fileName = e.target.files[0].name;
      BusyLoader.showLoader();
      let imagePath =
        `attachments/${qPath}/${state?.eventId}/${fileName}` +
        "-" +
        new Date().getTime();

      let qParam: string = "objectPath=" + imagePath;
      NetworkManager.sendJsonRequest(
        "api/session/uploadUrl",
        "",
        qParam,
        "GET",
        false,
        (dataObj: any) => {
          if (dataObj) {
            if (dataObj.statusCode) {
              BusyLoader.hideLoader();
              var errMsg = StatusCodeUtil.getCodeMessage(dataObj.statusCode);
              ToastManager.showToast(errMsg, "Error");
            } else {
              var strUploadPath = dataObj;

              const formData = new FormData();
              var myHeaders = new Headers();
              var requestOptions: any = {
                method: "PUT",
                headers: myHeaders,
                body: file,
                redirect: "follow",
              };
              fetch(strUploadPath, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                  BusyLoader.hideLoader();
                  dispatch({
                    type: "ctpatSealImage",
                    payload: {
                      images: { fullFilePath: imagePath },
                    },
                  });
                  //uploadClaimImages(imagePath);
                })
                .catch((error) => {
                  console.log("error", error);
                });
            }
          }
        },
        onLoginError
      );
    }
  };
  return (
    <Form onSubmit={submitHandler}>
      <div>
        <div
          className="section"
          style={{
            paddingRight: "12px",
            height: "420px",
            minHeight: "420px",
            overflowY: "scroll",
          }}
        >
          <Table bordered hover>
            <thead>
              <tr>
                <th style={{ width: "200px" }}>INSPECTION ITEM</th>
                <th style={{ width: "120px" }}>
                  <div className="d-flex">
                    <div>PASS</div>
                    <div style={{ marginLeft: "32px" }}>FAIL</div>
                  </div>
                </th>
                <th>
                  <div className="text-center">NOTE</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {ctpatInspection?.map((data: any) => {
                return (
                  <NewEntryCTPATValues
                    data={data}
                    loadStatus={loadStatus}
                    key={data.taskId}
                    onAddPictures={onAddPictures}
                  />
                );
              })}
              {state?.loadStatus && (
                <tr>
                  <th>
                    <td>Seal Image</td>
                  </th>
                  <th colSpan={2}>
                    <td>
                      <div className="d-flex ">
                        {state?.ctpatInspection?.sealImages?.images?.length ? (
                          <div
                            style={{
                              textDecoration: "underline",
                              color: "blue",
                              padding: "4px",
                              paddingLeft: "5px",
                              paddingRight: "5px",
                              borderRadius: "3px",
                              marginRight: "5px",
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              AttachmentsViewer.show({
                                attachmentUploads:
                                  state?.ctpatInspection?.sealImages?.images,
                                attachmentDamageDescription:
                                  state?.ctpatInspection?.sealImages
                                    ?.description,
                                attachmentDetails: {
                                  category: "ctpatInspection",
                                  key: "sealImages",
                                },
                                isDeleteButton: true,
                                resetData: "ctpatInspection",
                              })
                            }
                          >
                            {state?.ctpatInspection?.sealImages?.images?.length}
                          </div>
                        ) : null}
                        &nbsp;
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            onAddPictures(
                              data?.taskId,
                              CommonUtil.RELEASES_PICS
                            );
                          }}
                        >
                          <i className="fa-solid fa-cloud-arrow-up"></i>
                        </span>{" "}
                        &nbsp;
                        {state?.ctpatInspection?.sealImages?.images?.length ? (
                          <>
                            <input
                              className="textarea"
                              required
                              type="text"
                              style={{ width: "75%" }}
                              value={
                                state?.ctpatInspection?.sealImages?.description
                              }
                              onChange={(event: any) => {
                                dispatch({
                                  type: "ctpatSealImage",
                                  payload: {
                                    description: event.target.value,
                                  },
                                });
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                  </th>
                </tr>
              )}
            </tbody>

            <input
              type="file"
              ref={fileref2}
              className="custom-file-doc-input"
              multiple={false}
              id="inputGroupFile2"
              accept="image/*;pdf/*"
              style={{ display: "none" }}
              onChange={(e) => {
                onSelectFiles(e);
              }}
            />
          </Table>
        </div>
        <div className="d-flex justify-content-end text-right w-100 mb-2">
          <button type="submit" className="btn btn-success btn-lg">
            Save
          </button>
        </div>
      </div>
    </Form>
  );
};

export default NewEntryCTPATInsp;
