import React from "react";

const RibbonTag = () => {
  return (<div style={{position:"relative"}}>
  <style>
    {`
    .ribbon {
        position: absolute;
        right: -5px;
        top: -5px;
        z-index: 1;
        overflow: hidden;
        width: 250px;
        height: 50px;
        text-align: right;
    }
    .ribbon .banner {
        // font-size: 0.8rem;
        color: #fff;
        // text-transform: uppercase;
        text-align: right;
        padding-right: 15px;
        // font-weight: bold;
        line-height: 32px;
        // transform: rotate(45deg);
        width: 125px;
        display: block;
        background: #79a70a;
        // box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
        position: absolute;
        right: 0px;
        top: 17px; 
    }
    .ribbon .checkIcon {
        color: #79a70a;
        font-size: 0.8rem;
        text-align: center;
        position: absolute;
        right:100px;
        top: 20px; 
        z-index: 99;
    }
    .ribbon .playIcon {
        color: #ffffff;
        font-size: 30px;
        text-align: center;
        position: absolute;
        right: 105px;
        top: 11px;
        z-index: 99;
    }
    `}
  </style>
    <div className="ribbon">
    {/* <span className="checkIcon"><i className="fa-regular fa-circle-check"></i></span> */}
    <span className="playIcon"><i className="fa-solid fa-play"></i></span>
      <span className="banner">Completed</span>
    </div>
  </div>);
};

export default RibbonTag;
