import React from "react";
import {Link, Route, Routes} from "react-router-dom";
import DataGrid from "../../Components/DataGrid";
import GoogleMap from "../../Components/GoogleMap";
import Page3 from "../../Components/Page3";
import ImageUpload from "../Upload/ImageUpload";
import Header from "./Header.";
import TestPage from "./TestPage";
import OrganizationList from "../Organization/OrganizationList";
import OrganizationDetails from "../Organization/OrganizationDetails";
import Driver from "../Admin/Driver/Driver";
import ParkingLot from "../Admin/ParkingLot/ParkingLot";
import CustomerProfiles from "../CustProfiles/CustomerProfiles";
import EquipmentList from "../Equipments/EquipmentList";
import CreateEquipment from "../Equipments/CreateEquipment";
import EquipmentViewList from "../Equipments/EquipmentViewList";
import EquipmentView from "../Equipments/EquipmentView";
import MenuHeader from "./MenuHeader";
import AvailabilityReport from "../Equipments/AvailabilityReport";
import CreateEquipAvail from "../Equipments/CreateEquipAvail";
import EquipPublicView from "../Equipments/EquipPublicView";
import Notification from "../Notification/Notification";
import SearchDashboard from "../DashBoards/SearchDashboard";
import ReservationList from "../Equipments/ReservationList";
import EquipmentReservation from "../Equipments/EquipmentReservation";
import ReservationView from "../Equipments/ReservationView";
import FavoritiesList from "../Equipments/FavoritiesList";
import OfferReport from "../Equipments/OfferReport";
import OffersMade from "../Reports/OffersMade";
import OffersReceived from "../Reports/OffersReceived";
import OwnerReservation from "../Reports/OwnerReservation";
import RenterReservation from "../Reports/RenterReservation";
import DashBoard360 from "../DashBoards/DashBoard360";
import SearchDashBoardPage from "../DashBoards/SearchDashBoardPage";
import ProcessReservations from "../Reports/ProcessReservations";
import ReservationProcessView from "../Equipments/ReservationProcessView";
import ApiHub from "../API/ApiHub";
import SearchDashboardNew from "../DashBoards/SearchDashboardNew";
import MasterData from "../MasterData/MasterData";
import InvoiceReport from "../DashBoards/InvoiceReport";
import InsurancePlans from "../Insurance/InsurancePlans";
import InsurancePlanRateTable from "../Insurance/InsurancePlanRateTable";
// import InvoiceReport from "../DashBoards/InvoiceReport";
import EditCompany from "../InspectionCompany/EditCompany";
import AddInspectionCo from "../InspectionGrid/AddInspectionCo";
import InterchangeApplicationDetails from "../InterchangeApplication/InterchangeApplicationDetails";
import InterchangeDocumentDetails from "../InterchangeApplication/InterchangeDocumentDetails";
import InterchangeEntryInspection from "../InterchangeApplication/InterchangeEntryInspection";
import InterchangeExitInspection from "../InterchangeApplication/InterchangeExitInspection";
import ExitCompare from "../InterchangeApplication/ExitCompare";
import SearchMapDashboard from "../DashBoards/SearchMapDashBoard";
import SearchDashboardCopy from "../DashBoards/SearchDashboardCopy";
import ReservationReport from "../Reports/ReservationReport";
import InterchangeEntryInspectionReleases from "../InterchangeApplication/InterchangeEntryInspectionReleases";
import { ReleaseInspectionProvider } from "../../Components/ReleaseContext";
import MultiReleaseCompare from "../InterchangeApplication/NewEntries/MultiReleaseCompare";
import Consolidatedinvoice from "../Reports/consolidatedinvoice";
import ConsolidatedStatement from "../Reports/consolidatedStatement";

const MenuPage = () => {

    return (
        <>
            <div className="main">
               <MenuHeader />
                {/*<Header/>*/}
                <Routes>
                    <Route path="orgList" element={< OrganizationList/>}></Route>
                    <Route path="searchdashboard" element={< SearchDashboard />}></Route>
                    <Route path="searchdashboard/equippublic" element={< EquipPublicView />}></Route>
                    <Route path="equipments" element={< EquipmentList/>}></Route>
                    <Route path="equipview" element={< EquipmentView/>}></Route>
                    <Route path="equipview/editequip" element={< CreateEquipment/>}></Route>
                    <Route path="equipments/equipviewdetails" element={< EquipmentView/>}></Route>
                    <Route path="equipments/equipviewdetails/availability" element={ <CreateEquipAvail />}></Route>
                    <Route path="equipDetails" element={< CreateEquipment/>}></Route>
                    <Route path="viewreservation/equipDetails" element={< CreateEquipment/>}></Route>
                    <Route path="equipments/equipDetails" element={< CreateEquipment/>}></Route>
                    <Route path="equipments/equippublic" element={< EquipPublicView/>}></Route>
                    <Route path="equipments/equipviewdetails/editequip" element={< CreateEquipment/>}></Route>
                    <Route path="custProfiles" element={< CustomerProfiles/>}></Route>
                    <Route path="orgList/orgDetails" element={< OrganizationDetails/>}></Route>
                    <Route path="driver" element={<Driver/>}></Route>
                    {/*<Route path="notification" element={<Notification/>}></Route>*/}
                    <Route path="availabilities" element={<AvailabilityReport />}></Route>
                    <Route path="availabilities/equippublic" element={<EquipPublicView />}></Route>
                    <Route path="parking" element={<ParkingLot/>}></Route>
                    <Route path="googlemap" element={< GoogleMap/>}></Route>
                    <Route path="graph" element={< Page3/>}></Route>
                    <Route path="upload" element={<ImageUpload/>}></Route>
                    <Route path="reservations" element={<ReservationList />}></Route>
                    <Route path="offers" element={<OfferReport />}></Route>
                    <Route path="favorities" element={<FavoritiesList />}></Route>
                    <Route path="reservations/viewreservation" element={<ReservationView />}></Route>
                    <Route path="viewreservation" element={<ReservationView />}></Route>
                    <Route path="equippublic/viewreservation" element={<ReservationView />}></Route>
                    <Route path="notifications" element={<Notification/>}></Route>
                    <Route path="offersmade" element={<OffersMade />} />
                    <Route path="offersreceived" element={<OffersReceived />} />
                    <Route path="ownerreservations" element={<OwnerReservation />} />
                    <Route path="renterreservations" element={<RenterReservation />} />
                    <Route path="adminreservations" element={<ReservationReport />} />
                    <Route path="consolidatedinvoice" element={<Consolidatedinvoice />} />
                    <Route path="consolidatedstatement" element={<ConsolidatedStatement />} />
                    <Route path="renterreservations/viewreservation" element={<ReservationView />}></Route>
                    <Route path="equippublic" element={<EquipPublicView />}></Route>
                    <Route path="companydb" element={<DashBoard360 />}></Route>
                    <Route path="companydb/invoice" element={<InvoiceReport />}></Route>
                    <Route path="invoice" element={<InvoiceReport />}></Route>
                    <Route path="viewreservation/invoice" element={<InvoiceReport />}></Route>
                    <Route path="searchdb" element={<SearchDashboardNew />}></Route>
                    <Route path="newsearchdb" element={<SearchDashboardCopy />}></Route>
                    <Route path="processReserve" element={<ProcessReservations />}></Route>
                    <Route path="processReserve/processview" element={<ReservationProcessView />}></Route>
                    <Route path="processReserve/processview/processReserve" element={<ProcessReservations />}></Route>
                    <Route path="apihub" element={<ApiHub />}></Route>
                    <Route path="masterdata" element={<MasterData />}></Route>
                    <Route path="insurplans" element={<InsurancePlans />}></Route>
                    <Route path="insurRatePlans" element={<InsurancePlanRateTable />}></Route>
                    <Route path="inspectionCompany" element={<AddInspectionCo />}></Route>
                    <Route path="inspectionCompany/EditCompany" element={<EditCompany/>}></Route>
                    <Route path="interchangeApplication" element={<InterchangeApplicationDetails/>}></Route>
                    <Route path="interchangeApplication/exitCompare" element={<MultiReleaseCompare/>}></Route>
                    {/* <Route path="interchangeApplication/exitCompare" element={<ExitCompare/>}></Route> */}
                    <Route path="interchangeApplication/interchangeDocumentDetails" element={<InterchangeDocumentDetails/>}></Route>
                    <Route path="interchangeApplication/interchangeDocumentDetails/entryInspection" element={<InterchangeEntryInspection/>}></Route>
                    <Route path="interchangeApplication/interchangeDocumentDetails/entryInspectionReleases" element={ <ReleaseInspectionProvider><InterchangeEntryInspectionReleases/></ReleaseInspectionProvider>}></Route>
                    <Route path="interchangeApplication/interchangeDocumentDetails/exitInspection" element={<InterchangeExitInspection/>}></Route>
                </Routes>
            </div>
        </>
    );
}

export const Home = () => {
    return <div>You are in Home page</div>
}
export const About = () => {
    return <div>This is the page where you put details about yourself</div>
}
export const NotFound = () => {
    return <div>This is a 404 page</div>
}

export default MenuPage;
